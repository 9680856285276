import React, { useEffect, useState } from "react";
import "./css/style.css";

import {
  fetchPlanLimitDetails,
  updateUserGender,
} from "backendServices/ApiCalls";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";

const CommentAI = ({}) => {
  const [showVideo, setShowVideo] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [planLimit, setPlanLimit] = useState({});
  const [gender, setGender] = useState(planLimit.new_packages?.gender);
  const { t } = useTranslation();

  const handleGenderChange = (e) => {
    const genderVal = e.target.value;
    let params = {
      gender: genderVal,
    };
    setGender(genderVal);
    updateUserGender(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: t("pages.title.Updated Successfully"),
            variant: "success",
          });
          
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
      }
    );
  };

  const fetchPlanLimitDetailsFunction = async () => {
    let params = {};
    fetchPlanLimitDetails(
      params,
      (response) => {
        if (response?.data?.data) {
          setPlanLimit(response?.data?.data);
          setGender(response?.data?.data?.new_packages?.gender);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fetchPlanLimitDetailsFunction();
  }, []);

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <section className="comment-ai-sec">
        <div className="container">
          <div className="ai-commentment-wrap">
            <div className="ai-commentment-hd-wraps">
              <h2 className="ai-commentment-hd">
                {" "}
                {t("pages.title.Generate Automated AI Comment")}
              </h2>
            </div>
            <div className="ai-commentment-videos">
              <div className="ai-commentment-video">
                <iframe
                  width="853"
                  height="480"
                  src={t("pages.title.comment_video")}
                  title="Novalya - Dashboard tour"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="ai-commentment-gender-wrap">
                <div className="ai-commentment-gender">
                  <h2 className="ai-commentment-gender-hd">
                    {t("pages.title.Select Your Gender")}
                  </h2>
                  <div className="message-right">
                    <label className="group-items" for="male">
                      <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleGenderChange}
                      />
                      <div className="group-wraper-right">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 1.47266L13.4816 7.6482M20 1.47266V7.01151M20 1.47266H14.1536M8.30719 19.4727C10.2452 19.4727 12.1038 18.7433 13.4742 17.445C14.8445 16.1467 15.6144 14.3859 15.6144 12.5499C15.6144 10.7138 14.8445 8.95297 13.4742 7.6547C12.1038 6.35642 10.2452 5.62706 8.30719 5.62706C6.3692 5.62706 4.51059 6.35642 3.14023 7.6547C1.76986 8.95297 1 10.7138 1 12.5499C1 14.3859 1.76986 16.1467 3.14023 17.445C4.51059 18.7433 6.3692 19.4727 8.30719 19.4727Z"
                            stroke="#170F49"
                            strokeWidth="1.65"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <span className="group-name">
                          {" "}
                          {t("pages.title.male")}
                        </span>
                      </div>
                    </label>
                    <label className="group-items" for="female">
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleGenderChange}
                      />
                      <div className="group-wraper-right">
                        <svg
                          width="16"
                          height="23"
                          viewBox="0 0 16 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.69439 15.0441C11.442 15.0441 14.4801 12.006 14.4801 8.25835C14.4801 4.51072 11.442 1.47266 7.69439 1.47266C3.94675 1.47266 0.908691 4.51072 0.908691 8.25835C0.908691 12.006 3.94675 15.0441 7.69439 15.0441Z"
                            stroke="#4C4C4C"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M7.6945 15.0439V21.4725M10.2838 18.6154H5.10522"
                            stroke="#4C4C4C"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>

                        <span className="group-name">
                          {" "}
                          {t("pages.title.female")}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="ai-commentment-cards">
              <div className="ai-commentment-opinion">
                <h3 className="ai-cards-hd">
                  {t("pages.title.ai_title_first")}
                </h3>
                <div className="ai-cards-inner">
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">01</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_first_first_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_first_first_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">02</h4>
                    <h3 className="ai-cards-inner-hd">
                      {" "}
                      {t("pages.title.ai_title_first_second_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_first_second_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">03</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_first_third_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_first_third_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">04</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_first_fourth_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_first_fourth_step_content")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ai-commentment-writing-style">
                <h3 className="ai-cards-hd">
                  {t("pages.title.ai_title_second")}
                </h3>
                <div className="ai-cards-inner ai-cards-writing-inner">
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">01</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_second_first_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_second_first_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">02</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_second_second_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_second_second_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">03</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_second_third_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_second_third_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">04</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_second_fourth_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_second_fourth_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">05</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_second_fifth_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_second_fifth_step_content")}
                    </p>
                  </div>
                  <div className="ai-cards-inner-div">
                    <h4 className="ai-cards-inner-number">06</h4>
                    <h3 className="ai-cards-inner-hd">
                      {t("pages.title.ai_title_second_sixth_step")}
                    </h3>
                    <p className="ai-cards-inner-content">
                      {t("pages.title.ai_title_second_sixth_step_content")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ai-commentment-writing-style">
              <h3 className="ai-cards-hd">{t("pages.title.ai_title_third")}</h3>
              <div className="ai-cards-inner ai-cards-writing-inner">
                <div className="ai-cards-inner-div">
                  <h4 className="ai-cards-inner-number">01</h4>
                  <h3 className="ai-cards-inner-hd">
                    {t("pages.title.ai_title_third_first_step")}
                  </h3>
                  <p className="ai-cards-inner-content">
                    {t("pages.title.ai_title_third_first_step_content")}
                  </p>
                </div>
                <div className="ai-cards-inner-div">
                  <h4 className="ai-cards-inner-number">02</h4>
                  <h3 className="ai-cards-inner-hd">
                    {t("pages.title.ai_title_third_second_step")}
                  </h3>
                  <p className="ai-cards-inner-content">
                    {t("pages.title.ai_title_third_second_step_content")}
                  </p>
                </div>
                <div className="ai-cards-inner-div">
                  <h4 className="ai-cards-inner-number">03</h4>
                  <h3 className="ai-cards-inner-hd">
                    {t("pages.title.ai_title_third_third_step")}
                  </h3>
                  <p className="ai-cards-inner-content">
                    {t("pages.title.ai_title_third_third_step_content")}
                  </p>
                </div>
                <div className="ai-cards-inner-div">
                  <h4 className="ai-cards-inner-number">04</h4>
                  <h3 className="ai-cards-inner-hd">
                    {t("pages.title.ai_title_third_fourth_step")}
                  </h3>
                  <p className="ai-cards-inner-content">
                    {t("pages.title.ai_title_third_fourth_step_content")}
                  </p>
                </div>
                <div className="ai-cards-inner-div">
                  <h4 className="ai-cards-inner-number">05</h4>
                  <h3 className="ai-cards-inner-hd">
                    {t("pages.title.ai_title_third_fifth_step")}
                  </h3>
                  <p className="ai-cards-inner-content">
                    {t("pages.title.ai_title_third_fifth_step_content")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommentAI;
