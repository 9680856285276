import React, { useState, useEffect, useCallback } from "react";
import "./TermPlan.css";
// import NovalyaLogo from "../../../../../public/images/novalya-blue.png";
// import NovalyaLogoWhite from "../../../../../public/images/novalya-white.png";
import NovaLogo from "../../../../assets/img/NOVALYA.png";
import checkedCard from "../../../../assets/img/checked-card.png";
import checkedCardDark from "../../../../assets/img/checked-card-dark.png";
import CheckImg from "../../../../assets/img/check-img.png";
import CrossImg from "../../../../assets/img/cross-img.png";
import Vector from "../../../../assets/img/Vector-info.png";
import TrailLeft from "../../../../assets/img/trail-left.png";
import popular from "../../../../assets/img/popular.svg";
import icon1 from "../../../../assets/img/i-icon-01.png";
import icon2 from "../../../../assets/img/i-icon-02.png";
import icon3 from "../../../../assets/img/i-icon-03.png";
import icon4 from "../../../../assets/img/i-icon-04.png";
import icon5 from "../../../../assets/img/i-icon-05.png";
import icon6 from "../../../../assets/img/i-icon-06.png";
import { getCompanyLogo, getPlans } from "backendServices/ApiCalls";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import { useTranslation } from "react-i18next";

import PlanOfferPOP from "app/layouts/shared/offersPop/plansPop";
import { useJumboTheme } from "@jumbo/hooks";

const Terms = [
  {
    id: 134,
    subdomain: "app",
    plan_name: null,
    plan_id: "Basic-Novalya-EUR-Yearly",
    currency_code: "EUR",
    period_unit: "year",
    family_id: "Novalya",
    amount_1: "290",
    amount_2: "97",
    plan_type: "Novalya-all",
    connections: 1,
    limits: "Basic",
    trial: "Yes",
    status: 1,
    dueToday: 290,
  },
  {
    id: 136,
    subdomain: "app",
    plan_name: null,
    plan_id: "Business-Novalya-EUR-Yearly",
    currency_code: "EUR",
    period_unit: "year",
    family_id: "Novalya",
    amount_1: "490",
    amount_2: "197",
    plan_type: "Novalya-all",
    connections: 2,
    limits: "Business",
    trial: "Yes",
    status: 1,
    dueToday: 490,
  },
  {
    id: 138,
    subdomain: "app",
    plan_name: null,
    plan_id: "Unlimited-Novalya-EUR-Yearly",
    currency_code: "EUR",
    period_unit: "year",
    family_id: "Novalya",
    amount_1: "690",
    amount_2: "297",
    plan_type: "Novalya-all",
    connections: 2,
    limits: "Unlimited_new",
    trial: "Yes",
    status: 1,
    dueToday: 690,
  },
];

const FamClub = () => {
  const { t, i18n } = useTranslation();

  const [isAnnual] = useState("year");
  const [monthDivision] = useState(12);
  const [loader, setLoader] = useState(false);
  const [plan1, setPlan1] = useState({ amount_2: 0, amount_1: 0 });
  const [plan2, setPlan2] = useState({ amount_2: 0, amount_1: 0 });
  const [plan3, setPlan3] = useState({ amount_2: 0, amount_1: 0 });
  const [allPlans] = useState(Terms);
  const [currency, setCurrency] = useState("");
  const [selectedOption, setSelectedOption] = useState("pl-facebook");
  const { theme } = useJumboTheme();

  function getSubdomain() {
    try {
      const url = window.location.href;
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname;
      const domainParts = hostname.split(".");

      if (domainParts.length >= 2) {
        const domain = domainParts.slice(0, -2).join(".");
        // const domain = domainParts.slice(0, -1).join(".");
        if (domain.includes("localhost") || domain.includes("staging-app")) {
          return "app";
        }
        return domain;
      }
      return "app";
    } catch (error) {
      return null;
    }
  }

  const callPlansFunction = useCallback(async () => {
    setLoader(false);

    const langList = [
      { title: "en", value: "en-US" },
      { title: "fr", value: "fr-FR" },
      { title: "de", value: "de-DE" },
      { title: "es", value: "es-ES" },
      { title: "pt", value: "pt-PT" },
    ];
    let regionCode = localStorage.getItem("regionCode");
    if (!regionCode) {
      const res = await (
        await fetch(
          "https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306"
        )
      ).json();

      regionCode = res?.continent_code;
      const lang = res?.languages?.split(",")?.[0]?.split("-")?.[0];

      const selectedLang =
        langList.find(
          (data) => data?.title.toUpperCase() === lang?.toUpperCase()
        )?.value || "en-US";

      i18n.changeLanguage(lang);
      localStorage.setItem("selectedLocale", selectedLang);
      localStorage.setItem("regionCode", regionCode);
    }

    let country = "EUR";
    setCurrency("€");

    const filteredPLans = allPlans.filter(
      (plan) =>
        plan?.period_unit === isAnnual && plan?.currency_code === country
    );
    setPlan1(filteredPLans?.[0]);
    setPlan2(filteredPLans?.[1]);
    setPlan3(filteredPLans?.[2]);

    localStorage.setItem("plans", JSON.stringify(allPlans));
  }, [isAnnual, selectedOption]);

  const navigate = useNavigate();

  const handleBuyNow = (planId, coupon) => {
    localStorage.setItem("planId", planId);
    localStorage.setItem("backto", "/famclub");
    localStorage.setItem("referralId", "SANDRA");
    navigate(`/signup-famcam?coupon_code=${coupon}`);
    window.dataLayer.push({ event: "Abonnement" });
  };

  function getCompanyLogos() {
    if (window.location.hostname === "localhost") {
      var testDomainText = "admin-app.com";
      var testDomain = testDomainText.split(".");
    } else {
      testDomain = window.location.hostname.split(".");
    }
    const domainParts = testDomain;

    if (domainParts.length >= 1 && domainParts[0]) {
      let final_domain;
      if (domainParts[0].includes("-")) {
        const parts = domainParts[0].split("-");
        final_domain = parts[1];
      } else {
        final_domain = domainParts[0];
      }

      getCompanyLogo({ domain: final_domain }).then((response) => {
        const favicon = document.querySelector('link[rel="icon"]');

        if (favicon && response.data.data.logo_val) {
          favicon.href = response.data.data.logo_val;
          //   setCompanyLogo(response.data.data.logo_val);
        }

        if (response.data.data.company) {
          var capitalizedCompany =
            response.data.data.company.charAt(0).toUpperCase() +
            response.data.data.company.slice(1);
          //   document.title = capitalizedCompany + "";
        }
      });
    }
  }

  useEffect(() => {
    callPlansFunction();
    getCompanyLogos();
  }, [callPlansFunction, isAnnual, selectedOption]);

  const selectPeriod = (val) => {
    if (val === "year") {
      val = t("Billed Yearly");
    } else {
      val = t("Billed Quarterly");
    }

    return val;
  };

  const changeCurrencyText = (string) => {
    let updatedString = string.replace("€", currency);

    return updatedString;
  };

  return (
    <>
      <div className="TermPlan">
        <header class="aug-header aug-headerFam">
          <div class="container">
            <div class="aug-hd-logo">
              {/* <div>
					<img src='/images/novalya-blue.png' alt="Logo" />
				</div> */}
              <div>
                <img
                  src={
                    theme.mode === "light"
                      ? "/images/novalya-blue.png"
                      : "/images/novalya-white.png"
                  }
                  alt="Logo"
                />
              </div>
              <div style={{ width: "170px" }}>
                <LocalizationOptions />
              </div>
            </div>
          </div>
        </header>
        <section class="aug-plan-main">
          <div class="container">
            <div class="aug-plan-img">
              <img src={NovaLogo} />
            </div>
          </div>
        </section>

        <section class="aug-card-section ug-card-sectionFam">
            {/* <div class='fanclub-overlay'>
              <img src="https://i.pinimg.com/736x/04/4a/63/044a638b75694e1dd3d123c59ba2c21a.jpg" />
              <span> {t("fam.FAM Club Special offer")} <br/> 
                <span class='coming-soon-famClub'> {t("fam.Coming soon...")} </span> 
              </span>
            </div> */}
          <div class="container">
            {/* <div class="famCount">
              <div class="famCountTime">
                <span class="famCountDigit">01</span>
                <span class="famCountText">Days</span>
              </div>
              <div class="famCountTime">
                <span class="famCountDigit">10</span>
                <span class="famCountText">Hours</span>
              </div>
              <div class="famCountTime">
                <span class="famCountDigit">50</span>
                <span class="famCountText">Minutes</span>
              </div>
              <div class="famCountTime">
                <span class="famCountDigit">01</span>
                <span class="famCountText">Seconds</span>
              </div>
            </div> */}
            
            <h2 class="fanclub-title">
              <img src="https://i.pinimg.com/736x/04/4a/63/044a638b75694e1dd3d123c59ba2c21a.jpg" />{" "}
              {t("fam.Fam Club Special Offer | Up to 70% OFF")}
            </h2>
            <div class="aug-plan-discount-cards">
              <div class="aug-starter-discont-cards aug-starter-side--card">
                <h3 class="aug-card-lg-hd">BASIC</h3>
                <p class="aug-card-sm-hd">
                  {t("pricing.To kick off your business")}
                </p>
                <ul class="aug-card-section-list">
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>
                      {"1200 " + t("pricing.Facebook Messages per month")}
                    </span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>
                      {"800 " + t("pricing.Instagram Messages per month")}
                    </span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>{"800 " + t("pricing.AI Credits per month")}</span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>{"10 " + t("pricing.Tags + Pipelines")}</span>
                  </li>
                  <li class="rael-humans-gray">
                    <img src={checkedCardDark} alt="" />
                    <span>{t("pricing.Live chat with real humanss")}</span>
                  </li>
                </ul>
                <h1 class="aug-card-discount-text discount-strike">
                  {/* {
						!loader ? (
						<>
							{`${plan1?.currency_code === "USD"?"$":"€"}${Math.ceil(290 /monthDivision)}`}<span class="aug-small-year">{t("pricing./month")}</span>
						</>) : (
						<>
							{`${plan1?.currency_code === "USD"?"$":"€"}__`}<span class="aug-small-year">{t("pricing./month")}</span>
						</>)
					} */}
				  {t("fam.Normal_price",{price:756})}

                </h1>
                {/* <p class="aug-card-sm-hd">{isAnnual === "month"? t("pricing.No commitment"):`${plan1?.currency_code === "USD"?"$":"€"}${
					290} ${selectPeriod(isAnnual)}`}</p> */}
                <p class="aug-card-sm-Fam">
                  {" "}
                  {t("fam.Special Fam Club Offer")}<br /> {t("fam.Only /year",{price:290})}
                </p>
                <button
                  class="aug-card-chose-btn"
                  onClick={() =>
                    handleBuyNow(
                      "Basic-Novalya-EUR-Yearly",
                      "famclub-basic-12m"
                    )
                  }
                >
                  {t("fam.Start Now")}
                </button>
              </div>
              <div class="aug-starter-discont-cards aug-starter-side-mid">
                <div class="aug-popular">
                  <img src={popular} alt="" />
                </div>
                <h3 class="aug-card-lg-hd">BUSINESS</h3>
                <p class="aug-card-sm-hd">
                  {t("pricing.To get to the next level")}
                </p>
                <ul class="aug-card-section-list">
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>
                      {"2400 " + t("pricing.Facebook Messages per month")}
                    </span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>
                      {" "}
                      {"1600 " + t("pricing.Instagram Messages per month")}
                    </span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span> {"2000 " + t("pricing.AI Credits per month")}</span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span> {"25 " + t("pricing.Tags + Pipelines")}</span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>{t("pricing.Live chat with real humanss")}</span>
                  </li>
                </ul>
                <h1 class="aug-card-discount-text discount-strike">
                  {/* {
						!loader ? (
						<>
							{`${plan2?.currency_code === "USD"?"$":"€"}${Math.ceil(490/monthDivision)}`}<span class="aug-small-year">{t("pricing./month")}</span>
						</>) : (
						<>
							{`${plan2?.currency_code === "USD"?"$":"€"}__`}<span class="aug-small-year">{t("pricing./month")}</span>
						</>)
					} */}
                 
				  {t("fam.Normal_price",{price:1536})}
                </h1>
                {/* <p class="aug-card-sm-hd">{isAnnual === "month"? t("pricing.No commitment"):`${plan2?.currency_code === "USD"?"$":"€"}${
					490} ${selectPeriod(isAnnual)}`}</p> */}
                <p class="aug-card-sm-Fam">
                  {" "}
				  {t("fam.Special Fam Club Offer")}<br /> {t("fam.Only /year",{price:490})}
                </p>
                <button
                  class="aug-card-chose-btn"
                  onClick={() =>
                    handleBuyNow(
                      "Business-Novalya-EUR-Yearly",
                      "famclub-business-12m"
                    )
                  }
                >
                  {t("fam.Start Now")}
                </button>
              </div>
              <div class="aug-starter-discont-cards aug-starter-side--card">
                <h3 class="aug-card-lg-hd">UNLIMITED</h3>
                <p class="aug-card-sm-hd">
                  {t("pricing.To unlock your full potential")}
                </p>
                <ul class="aug-card-section-list">
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>
                      {t("pricing.Facebook Messages per month Unlimited")}
                    </span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>
                      {" "}
                      {t("pricing.Instagram Messages per month Unlimited")}
                    </span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span> {t("pricing.AI Credits per month Unlimited")}</span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span> {t("pricing.Tags + Pipelines Unlimited")}</span>
                  </li>
                  <li>
                    <img src={checkedCard} alt="" />
                    <span>{t("pricing.Live chat with real humanss")}</span>
                  </li>
                </ul>
                <h1 class="aug-card-discount-text discount-strike">
                  {/* {
						!loader ? (
						<>
							{`${plan3?.currency_code === "USD"?"$":"€"}${Math.ceil(690 /monthDivision)}`}<span class="aug-small-year">{t("pricing./month")}</span>
						</>
						) : (
						<>
							{`${plan3?.currency_code === "USD"?"$":"€"}__`}<span class="aug-small-year">{t("pricing./month")}</span>
						</>)
					} */}
				  {t("fam.Normal_price",{price:2316})}

                </h1>
                {/* <p class="aug-card-sm-hd">{isAnnual === "month"? t("pricing.No commitment"):`${plan3?.currency_code === "USD"?"$":"€"}${
					690} ${selectPeriod(isAnnual)}`}</p> */}
                <p class="aug-card-sm-Fam">
                  {" "}
                  {t("fam.Special Fam Club Offer")}<br /> {t("fam.Only /year",{price:690})}
                </p>
                <button
                  class="aug-card-chose-btn"
                  onClick={() =>
                    handleBuyNow(
                      "Unlimited-Novalya-EUR-Yearly",
                      "famclub-unlimited-12m"
                    )
                  }
                >
                   {t("fam.Start Now")}
                </button>
              </div>
            </div>
          </div>
        </section>

        <section class="aug-card-media aug-card-facebook">
          <div class="container">
            <div class="aug-price-table">
              <ul class="aug-card-facebook-heading">
                <li class="aug-fb-hd">Facebook</li>
                <li class="aug-plan-hd">BASIC</li>
                <li class="aug-plan-hd">BUSINESS</li>
                <li class="aug-plan-hd">UNLIMITED</li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Auto Prospection in Groups")}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title1")}</h5>
                        <p>{t("pricing_tooltips.description.description1")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Advanced search by gender")}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title2")}</h5>
                        <p>{t("pricing_tooltips.description.description2")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Advanced search by keywords")}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title3")}</h5>
                        <p>{t("pricing_tooltips.description.description3")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Comment with AI")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title4")}</h5>
                        <p>{t("pricing_tooltips.description.description4")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Bulk Follow up messages")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title5")}</h5>
                        <p>{t("pricing_tooltips.description.description5")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {" "}
                    {t("pricing.Auto Wish Birthday")}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title6")}</h5>
                        <p>{t("pricing_tooltips.description.description6")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {" "}
                    {t("pricing.Auto Wish Birthday Later")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title7")}</h5>
                        <p>{t("pricing_tooltips.description.description7")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Auto reply on Accepted requests")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title8")}</h5>
                        <p>{t("pricing_tooltips.description.description8")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Auto clean pending requests")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title9")}</h5>
                        <p>{t("pricing_tooltips.description.description9")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
                <li>
                  <img src={CheckImg} alt="" />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Friends list importation")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title10")}</h5>
                        <p>
                          {t("pricing_tooltips.description.description10")}
                          <br></br>
                          <br></br>
                          <b>Basic: </b>
                          {t("pricing_tooltips.basic")}
                          <br></br>
                          <br></br>
                          <b>Advanced: </b>
                          {t("pricing_tooltips.advanced")}
                          <br></br>
                          <br></br>
                          <i>{t("pricing_tooltips.information")}</i>
                        </p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <span class="aug-face-importation">
                    {t("pages.title.Basic")}
                  </span>
                </li>
                <li>
                  <span class="aug-face-importation">
                    {t("pages.title.Advanced")}
                  </span>
                </li>
                <li>
                  <span class="aug-face-importation">
                    {t("pages.title.Advanced")}
                  </span>
                </li>
              </ul>
              <ul class="aug-card-facebook-list aug-table-btn">
                <li></li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan1?.plan_id)}
                  >
                    {t("fam.Start Now")}
                  </button>
                </li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan2?.plan_id)}
                  >
                  {t("fam.Start Now")}
                  </button>
                </li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan3?.plan_id)}
                  >
                    {t("fam.Start Now")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="aug-card-media aug-card-instagram">
          <div class="container">
            <div class="aug-price-table">
              <ul class="aug-card-facebook-heading">
                <li class="aug-fb-hd">Instagram</li>
                <li class="aug-plan-hd">BASIC</li>
                <li class="aug-plan-hd">BUSINESS</li>
                <li class="aug-plan-hd">UNLIMITED</li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Auto Message on Likes")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} alt="" />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title11")}</h5>
                        <p>{t("pricing_tooltips.description.description11")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Auto Message on Followers")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title12")}</h5>
                        <p>{t("pricing_tooltips.description.description12")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Auto Message on Hashtags")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title13")}</h5>
                        <p>{t("pricing_tooltips.description.description13")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Comment with AI")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title14")}</h5>
                        <p>{t("pricing_tooltips.description.description14")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Bulk Follow up messages")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title15")}</h5>
                        <p>{t("pricing_tooltips.description.description15")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list aug-table-btn">
                <li></li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan1?.plan_id)}
                  >
                    {t("fam.Start Now")}
                  </button>
                </li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan2?.plan_id)}
                  >
                   {t("fam.Start Now")}
                  </button>
                </li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan3?.plan_id)}
                  >
                    {t("fam.Start Now")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="aug-card-media aug-card-instagram aug-card-Support">
          <div class="container">
            <div class="aug-price-table">
              <ul class="aug-card-facebook-heading">
                <li class="aug-fb-hd">{t("pages.title.Support")}</li>
                <li class="aug-plan-hd">BASIC</li>
                <li class="aug-plan-hd">BUSINESS</li>
                <li class="aug-plan-hd">UNLIMITED</li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Explanation videos")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title16")}</h5>
                        <p>{t("pricing_tooltips.description.description16")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Multilingual Email Support 6/7")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title17")}</h5>
                        <p>{t("pricing_tooltips.description.description17")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {t("pricing.Live Chat with real humans")}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title18")}</h5>
                        <p>{t("pricing_tooltips.description.description18")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={CrossImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list">
                <li>
                  <span>
                    {" "}
                    {changeCurrencyText(
                      t("pricing.1-to-1 Kickstart Zoom Call (60€ value)")
                    )}{" "}
                    <div class="aug-tooltip-wrap">
                      <img src={Vector} />
                      <div class="aug-tooltiptext">
                        <h5>{t("pricing_tooltips.title.title19")}</h5>
                        <p>{t("pricing_tooltips.description.description19")}</p>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <img src={isAnnual === "year" ? CheckImg : CrossImg} />
                </li>
                <li>
                  <img src={isAnnual === "month" ? CrossImg : CheckImg} />
                </li>
                <li>
                  <img src={CheckImg} />
                </li>
              </ul>
              <ul class="aug-card-facebook-list aug-table-btn">
                <li></li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan1?.plan_id)}
                  >
                    {t("fam.Start Now")}
                  </button>
                </li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan2?.plan_id)}
                  >
                   {t("fam.Start Now")}
                  </button>
                </li>
                <li>
                  <button
                    class="aug-btn"
                    onClick={() => handleBuyNow(plan3?.plan_id)}
                  >
                  {t("fam.Start Now")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="aug-Also-Included-plans">
          <div class="container">
            <h1 class="aug-card-tab-hd">
              {t("pricing.Also Included in all plans")}{" "}
            </h1>
            <div class="aug-Also-Included-inner">
              <div class="aug-Included-inn">
                <div class="aug-Included-left">
                  <div class="aug-svg-outer">
                    <img src={icon1} />
                  </div>
                  <span> {t("pricing.Randomize Messages")}</span>
                </div>
                <div class="aug-Included-right aug-tooltip-wrap">
                  <img src={Vector} />
                  <div class="aug-tooltiptext">
                    <h5>{t("pricing_tooltips.title.title20")}</h5>
                    <p>{t("pricing_tooltips.description.description20")}</p>
                  </div>
                </div>
              </div>
              <div class="aug-Included-inn">
                <div class="aug-Included-left">
                  <div class="aug-svg-outer">
                    <img src={icon2} />
                  </div>
                  <span>{t("pricing.Unlimited Contacts")} </span>
                </div>
                <div class="aug-Included-right aug-tooltip-wrap">
                  <img src={Vector} />
                  <div class="aug-tooltiptext">
                    <h5>{t("pricing_tooltips.title.title21")}</h5>
                    <p>{t("pricing_tooltips.description.description21")}</p>
                  </div>
                </div>
              </div>
              <div class="aug-Included-inn">
                <div class="aug-Included-left">
                  <div class="aug-svg-outer">
                    <img src={icon3} />
                  </div>
                  <span>{t("pricing.Tagging Automation")} </span>
                </div>
                <div class="aug-Included-right aug-tooltip-wrap">
                  <img src={Vector} />
                  <div class="aug-tooltiptext">
                    <h5>{t("pricing_tooltips.title.title22")}</h5>
                    <p>{t("pricing_tooltips.description.description22")}</p>
                  </div>
                </div>
              </div>
              <div class="aug-Included-inn">
                <div class="aug-Included-left">
                  <div class="aug-svg-outer">
                    <img src={icon4} />
                  </div>
                  <span>{t("pricing.Don’t Target Same Users")} </span>
                </div>
                <div class="aug-Included-right aug-tooltip-wrap">
                  <img src={Vector} />
                  <div class="aug-tooltiptext">
                    <h5>{t("pricing_tooltips.title.title23")}</h5>
                    <p>{t("pricing_tooltips.description.description23")}</p>
                  </div>
                </div>
              </div>
              <div class="aug-Included-inn">
                <div class="aug-Included-left">
                  <div class="aug-svg-outer">
                    <img src={icon5} />
                  </div>
                  <span>{t("pricing.30+ Messages Templates")} </span>
                </div>
                <div class="aug-Included-right aug-tooltip-wrap">
                  <img src={Vector} />
                  <div class="aug-tooltiptext">
                    <h5>{t("pricing_tooltips.title.title24")}</h5>
                    <p>{t("pricing_tooltips.description.description25")}</p>
                  </div>
                </div>
              </div>
              <div class="aug-Included-inn">
                <div class="aug-Included-left">
                  <div class="aug-svg-outer">
                    <img src={icon6} />
                  </div>
                  <span>{t("pricing.Pipelines Automation")} </span>
                </div>
                <div class="aug-Included-right aug-tooltip-wrap">
                  <img src={Vector} />
                  <div class="aug-tooltiptext">
                    <h5>{t("pricing_tooltips.title.title25")}</h5>
                    <p>{t("pricing_tooltips.description.description25")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer class="aug-plan-footer">
          <div class="container">
            <div class="aug-plan-footer-inn">
              <p>
                Novalya © 2023-2024 &nbsp;&nbsp; | &nbsp;&nbsp;{" "}
                <a
                  href="https://novalya.com/terms-and-conditions/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FamClub;
