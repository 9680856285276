import React, { useContext, useEffect, useRef, useState } from "react";
import "./AffiliateLinks.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { updateaffiliatecode } from "backendServices/ApiCalls";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AffiliateLinks = () => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [isALModalOpen, setIsALModalOpen] = useState(false);
  const [newAffiliateCode, setNewAffiliateCode] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [copyMessage, setCopyMessage] = useState("");
  const [copyMessage1, setCopyMessage1] = useState("");
  const [copyMessage2, setCopyMessage2] = useState("");
  const [copyMessage3, setCopyMessage3] = useState("");
  const [copyMessage111, setCopyMessage111] = useState("");
  const [copyMessage222, setCopyMessage222] = useState("");
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    const selectedLocale = localStorage.getItem("selectedLocale");
    switch (selectedLocale) {
      case "fr-FR":
        setSelectedLanguage("French");
        break;
      case "es-ES":
        setSelectedLanguage("German");
        break;
      case "sr-RS":
        setSelectedLanguage("English");
        break;
      default:
        setSelectedLanguage("English");
    }
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // Function to handle change in the input field
  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    // Convert lowercase alphabets to uppercase
    inputValue = inputValue.toUpperCase();
    // Remove special characters
    inputValue = inputValue.replace(/[^A-Z0-9]/g, "");
    setNewAffiliateCode(inputValue);
  };

  // Function to update affiliate code
  const updateAffiliateCode = () => {
    if (!isChecked) {
      setCopyMessage3("Please agreed terms and conditions.");
      setTimeout(() => setCopyMessage3(""), 3000);
    } else {
      let params = {
        affiliatecode: newAffiliateCode,
      };

      // alert(params.affiliatecode)
      updateaffiliatecode(
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: response?.data?.status,
            });
            setIsALModalOpen(false);
            setNewAffiliateCode("");
            setloginUserData((prevState) => ({
              ...prevState,
              randomcode: newAffiliateCode,
            }));
          } else if (response?.data?.status === "error") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: response?.data?.status,
            });
            setIsALModalOpen(false);
            setNewAffiliateCode("");
          } else {
            setalertData({
              show: true,
              message: "Something went wrong, Please try again later.",
              variant: "error",
            });
            setIsALModalOpen(false);
            setNewAffiliateCode("");
          }
        },
        (error) => { }
      );
    }
  };

  // Function to handle language selection
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  // Define affiliate links for different languages
  const affiliateLinks = {
    English: "https://app.novalya.com/redirect?page=demo&uname=",
    German: "https://app.novalya.com/redirect?page=demo&uname=",
    French: "https://app.novalya.com/redirect?page=demo&uname=",
  };

  const textRef = useRef(null);
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef111 = useRef(null);
  const textRef222 = useRef(null);

  const handleCopyClick = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage("Link copied");
    setTimeout(() => setCopyMessage(""), 3000);
  };

  const handleCopyClick1 = () => {
    const textToCopy = textRef1.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage1("Link copied");
    setTimeout(() => setCopyMessage1(""), 3000);
  };

  const handleCopyClick111 = () => {
    const textToCopy = textRef111.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage111("Link copied");
    setTimeout(() => setCopyMessage111(""), 3000);
  };

  const handleCopyClick222 = () => {
    const textToCopy = textRef222.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage222("Link copied");
    setTimeout(() => setCopyMessage222(""), 3000);
  };

  const handleCopyClick2 = () => {
    const textToCopy = textRef2.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage2("Link copied");
    setTimeout(() => setCopyMessage2(""), 3000);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: 50 }}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Grid item xs={12} md={6}>
        <div className="al-box-con">
          <div className="flex">
            <Typography variant="h5">
              {t("pages.title.MyAffiliateCodeis")}{" "}
              <span style={{ fontWeight: "bold" }}>
                {loginUserData?.randomcode}
              </span>
            </Typography>

            <Button
              variant="contained"
              // disabled={true}
              onClick={() => {
                setIsALModalOpen(true);
              }}
            >
              {t("pages.title.UpdateAffiliateCode")}
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="al-box-con">
          <div className="flex">
            <Typography variant="body2">
              {t("pages.title.SelectLanguageAffiliate")}
            </Typography>
            <select
              name=""
              id=""
              value={selectedLanguage}
              onChange={handleLanguageChange}
              style={{ width: "40%" }}
            >
              <option value="English">🇺🇸 English</option>
              <option value="German">🇩🇪 German</option>
              <option value="French">🇫🇷 French</option>
              <option value="Spanish">🇪🇸 Spanish</option>
            </select>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="al-box-con">
          <img src={`${ASSET_IMAGES}/official-website.png`} alt="" />
          <h3>{t("pages.title.OfficialWebsite")}</h3>
          {copyMessage && (
            <div style={{ color: "green", width: "100%" }}>{copyMessage}</div>
          )}
          <h4 style={{ height: 65 }}>
            <span style={{ textTransform: "lowercase" }} ref={textRef}>
              {/* {affiliateLinks[selectedLanguage]}
              {loginUserData?.randomcode} */}
              {`https://app.novalya.com/signup/${loginUserData?.randomcode}`}
            </span>
            <div style={{ cursor: "pointer" }}>
              <ContentCopyIcon onClick={handleCopyClick} />
            </div>
          </h4>
        </div>
      </Grid>

      {selectedLanguage === "French" &&
        loginUserData?.user_type === "Distributor" ? (
        <>
          <Grid item xs={12} md={6}>
            <div className="al-box-con">
              <img src={`${ASSET_IMAGES}/Sales-Funnel.jpg`} alt="" />
              <h3>{t("pages.title.SalesFunnel")}</h3>
              {copyMessage111 && (
                <div style={{ color: "green", width: "100%" }}>
                  {copyMessage111}
                </div>
              )}
              <h4>
                <span style={{ textTransform: "lowercase" }} ref={textRef111}>
                  {`https://www.novalya.ai/fr/go?uname=${loginUserData?.randomcode}&lang=fr`}
                </span>
                <div style={{ cursor: "pointer" }}>
                  <ContentCopyIcon onClick={handleCopyClick111} />
                </div>
              </h4>
            </div>
          </Grid>

          {/* 
          <Grid item xs={12} md={6}>
            <div className="al-box-con">
              <img src={`${ASSET_IMAGES}/image.png`} alt="" />
              <h3>{'Webinaire gratuit - 30 juin 18:00'}</h3>
              {copyMessage222 && (
                <div style={{ color: "green", width: "100%" }}>
                  {copyMessage222}
                </div>
              )}
              <h4>
                <span style={{ textTransform: "lowercase" }} ref={textRef222}>
                  {`https://www.novalya.ai/challenge?uname=${loginUserData?.randomcode}`}
                </span>
                <div style={{ cursor: "pointer" }}>
                  <ContentCopyIcon onClick={handleCopyClick222} />
                </div>
              </h4>
            </div>
          </Grid> */}

          {/* <Grid item xs={12} md={6}>
            <div className="al-box-con">
              <img src={`${ASSET_IMAGES}/Sales-Funnel-2.jpg`} alt="" />
              <h3>{t("pages.title.SalesFunnel")}</h3>
              {copyMessage1 && (
                <div style={{ color: "green", width: "100%" }}>
                  {copyMessage1}
                </div>
              )}
              <h4>
                <span style={{ textTransform: "lowercase" }} ref={textRef1}>
                  {`https://www.novalya.ai/affiliation?uname=${loginUserData?.randomcode}`}
                </span>
                <div style={{ cursor: "pointer" }}>
                  <ContentCopyIcon onClick={handleCopyClick1} />
                </div>
              </h4>
            </div>
          </Grid> */}
        </>
      ) : selectedLanguage === "English" &&
        loginUserData?.user_type === "Distributor" ? (
        <Grid item xs={12} md={6}>
          <div className="al-box-con">
            <img src={`${ASSET_IMAGES}/english.jpg`} alt="" />
            <h3>{"Sales Funnel"}</h3>
            {copyMessage111 && (
              <div style={{ color: "green", width: "100%" }}>
                {copyMessage111}
              </div>
            )}
            <h4>
              <span style={{ textTransform: "lowercase" }} ref={textRef111}>
                {`https://www.novalya.ai/en/go?uname=${loginUserData?.randomcode}&lang=en`}
              </span>
              <div style={{ cursor: "pointer" }}>
                <ContentCopyIcon onClick={handleCopyClick111} />
              </div>
            </h4>
          </div>
        </Grid>
      ) : selectedLanguage === "German" &&
        loginUserData?.user_type === "Distributor" ? (
        <Grid item xs={12} md={6}>
          <div className="al-box-con">
            <img src={`${ASSET_IMAGES}/german.jpg`} alt="" />
            <h3>{"Verkaufstrichter"}</h3>
            {copyMessage111 && (
              <div style={{ color: "green", width: "100%" }}>
                {copyMessage111}
              </div>
            )}
            <h4>
              <span style={{ textTransform: "lowercase" }} ref={textRef111}>
                {`https://www.novalya.ai/de/go?uname=${loginUserData?.randomcode}&lang=de`}
              </span>
              <div style={{ cursor: "pointer" }}>
                <ContentCopyIcon onClick={handleCopyClick111} />
              </div>
            </h4>
          </div>
        </Grid>
      ) : selectedLanguage === "Spanish" &&
        loginUserData?.user_type === "Distributor" ? (
        <Grid item xs={12} md={6}>
          <div className="al-box-con">
            <img src={`${ASSET_IMAGES}/spanish.jpg`} alt="" />
            <h3>{"Embudo de ventas"}</h3>
            {copyMessage111 && (
              <div style={{ color: "green", width: "100%" }}>
                {copyMessage111}
              </div>
            )}
            <h4>
              <span style={{ textTransform: "lowercase" }} ref={textRef111}>
                {`https://www.novalya.ai/es/go?uname=${loginUserData?.randomcode}&lang=es`}
              </span>
              <div style={{ cursor: "pointer" }}>
                <ContentCopyIcon onClick={handleCopyClick111} />
              </div>
            </h4>
          </div>
        </Grid>
      ) : null}

      <Grid item xs={12} md={6}>
        <div className="al-box-con">
          <img src={`${ASSET_IMAGES}/plans.png`} alt="" />
          <h3>{t("pages.title.link3text")}</h3>
          {copyMessage2 && (
            <div style={{ color: "green", width: "100%" }}>{copyMessage2}</div>
          )}
          <h4>
            <span style={{ textTransform: "lowercase" }} ref={textRef2}>
              {`https://app.novalya.com/redirect?page=plans&uname=${loginUserData?.randomcode}`}
            </span>
            <div style={{ cursor: "pointer" }}>
              <ContentCopyIcon onClick={handleCopyClick2} />
            </div>
          </h4>
        </div>
      </Grid>

      {isALModalOpen ? (
        <div className="al-modal">
          <div className="al-update">
            <div className="al-head">
              <h3>Update Affiliate Link</h3>
              <span
                onClick={() => {
                  setIsALModalOpen(false);
                }}
              >
                <CloseIcon />
              </span>
            </div>
            <div className="input-box">
              <label htmlFor="">Current Affiliate Code</label>
              <input type="text" disabled value={loginUserData?.randomcode} />
            </div>
            <div className="input-box">
              <label htmlFor="">New Affiliate Code</label>
              <input
                type="text"
                value={newAffiliateCode}
                onChange={handleInputChange}
                maxLength={10}
              />
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name=""
                id=""
                required
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="">
                I understand that all my previous links will be disabled and I
                want to update my affiliate links
              </label>
            </div>
            {copyMessage3 && (
              <div style={{ color: "red", width: "100%" }}>{copyMessage3}</div>
            )}
            <button onClick={updateAffiliateCode}>{t("pages.title.confirm")}</button>
          </div>
        </div>
      ) : null}
    </Grid>
  );
};

export default AffiliateLinks;
