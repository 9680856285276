import React, { useState, useEffect } from "react";
import {
  List,
  Grid,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  OutlinedInput,
  Chip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import {
  createMessage,
  fetchMessageList,
  fetchSectionList,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import "./message.css";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

const validationSchema = yup.object({
  name: yup.string().required("Message is required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 300,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CreateMessage = () => {
  const { t } = useTranslation();
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [personName, setPersonName] = useState([]);
  const theme = useTheme();
  const [sectionData, setSectionData] = useState([]);
  const [typefor, setTypefor] = useState();
  const [messageData, setMessageData] = useState([]);
  useEffect(() => {
    fetchSectionList(
      {},
      (response) => {
        setSectionData(response?.data?.data);
      },
      (error) => {}
    );

    fetchMessageList(
      {},
      (response) => {
        if (response?.data?.data) setMessageData(response?.data?.data);
      },
      (error) => {}
    );
  }, []);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const navigate = useNavigate();
  const onSubmitForm = async (data, { setSubmitting }) => {
    const trimmedName = data.name.trim();

    // Check if 'name' is empty after trimming
    if (!trimmedName) {
      setAlertData({
        show: true,
        message: "Name cannot be empty",
        variant: "error",
      });
      setSubmitting(false);

      return;
    }

    if (messageData.some((message) => message.name === trimmedName)) {
      setAlertData({
        show: true,
        message: "Message with this name already exists",
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    const params = {
      name: data.name,
      sections: personName,
      types: typefor,
    };

    if (personName.length < 2) {
      setAlertData({
        show: true,
        message: "Please select atleast two sections",
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    if (!typefor || !typefor.length) {
      setAlertData({
        show: true,
        message: (
          <span>{`${t("pages.title.Please select atleast one tags")}`}</span>
        ),
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    createMessage(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setAlertData({
            show: true,
            message: t("pages.title.Created Successfully"),
            variant: "success",
          });
          setSubmitting(false);
          navigate("/messages");
        }
      },
      (error) => {
        setAlertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  const type = [
    { value: "connect", text: "Prospection", icon: <FacebookIcon /> },
    {
      value: "instagram-connect",
      text: "Prospection",
      icon: <InstagramIcon />,
    },
    { value: "crm", text: "Crm", icon: <FacebookIcon /> },
    { value: "instagram-crm", text: "Crm", icon: <InstagramIcon /> },
    { value: "birthday", text: "Birthday", icon: <FacebookIcon /> },
    { value: "request", text: "Request", icon: <FacebookIcon /> },
  ];

  return (
    <Box className="outer-contaier message-container message-container-create-new">
      <Box sx={{ m: 3 }}>
        <div>
          {" "}
          {alertData.show ? (
            <SweetAlert alertData={alertData} setalertData={setAlertData} />
          ) : (
            ""
          )}
        </div>
        <Box className="message-header" sx={{}}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("pages.title.create_message")}
          </Typography>
          <Button variant="outlined" onClick={() => navigate("/messages")}>
            <ArrowBackIosOutlinedIcon sx={{ height: "50px" }} />
          </Button>
        </Box>
        <List disablePadding className="message-wraper">
          <Formik
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
              name: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
            id="form-section"
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={6} className="half-field">
                    <p className="msg-label">Name of your Message</p>
                    <JumboTextField
                      fullWidth
                      label={t("pages.title.message_name")}
                      name="name"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="full-field">
                    <p className="msg-label">Select the Sections</p>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        fullWidth
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Section" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          const selectedNames = selected
                            .map(
                              (value) =>
                                sectionData.find((item) => item.id === value)
                                  ?.section
                            )
                            .filter(
                              (name) => name !== null && name !== undefined
                            );
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selectedNames.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          );
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Sections
                        </MenuItem>
                        {sectionData.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            style={getStyles(item.section, personName, theme)}
                          >
                            <span> {item.section}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <p className="msg-label msg-label-space">Select Type</p>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: "8px !important",
                      backgroundColor: "#F6F6F6",
                    }}
                  >
                    <ToggleButtonGroup
                      color="primary"
                      value={typefor}
                      onChange={(event, value) => setTypefor(value)}
                      aria-label="Platform"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                        "& .Mui-selected": {
                          backgroundColor: "#41CE81 !important",
                          color: "white !important",
                        },
                        button: {
                          borderRadius: "8px !important",
                        },
                      }}
                    >
                      {type.map((v, index) => {
                        return (
                          <ToggleButton
                            value={v.value}
                            key={index}
                            sx={{ gap: 1 }}
                          >
                            <span>{v.icon}</span>
                            <span>{v.text}</span>
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid
                    className="button-footer"
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <LoadingButton
                      fullWidth
                      id="form-section"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      <span> {t("pages.title.submit")}</span>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </List>
      </Box>
    </Box>
  );
};

export default CreateMessage;
