import { useEffect, useMemo, useState } from "react";
import "./indexnew.css";
import { FirstStep } from "./step-copy/first-step";
import { SecondStep } from "./step-copy/second-step";
import { ThirdStep } from "./step-copy/third-step";
import { ForthStep } from "./step-copy/fourth-step";
import { FifthStep } from "./step-copy/fifth-step";
import { SixthStep } from "./step-copy/sixth-step";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import {
  fetchGroupList,
  fetchKeywordList,
  fetchCrmGroups,
  createSocialTarget,
  fetchSocialProfileFeature,
  fetchTargetList,
  BASE_URL,
  getAllMessagesList,
} from "backendServices/ApiCalls";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { prospectionButtonsConfig } from "../prospection/TabData";
import { useLocation } from "react-router-dom";
import SweetAlert from "../../components/mui/Alerts/SweetAlert";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";
import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";
import CheckProfileModal from "../check-profile/CheckProfileModal";
import axios from "axios";
import { ZeroStep } from "./step/ZeroStep";
import { detectExtension } from "app/utils/appHelpers";
import { HiArrowLongRight } from "react-icons/hi2";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const stepCount = {
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
  step5: 5,
  step6: 6,
};
export function CopyConnect() {
  const location = useLocation();
  const { theme } = useJumboTheme();
  const { t } = useTranslation();
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  detectExtension((extensionStatus) => {
    setIsExtensionInstalled(extensionStatus);
  });

  const segments = location.pathname.split("/");
  const last = segments[segments.length - 1];
  const lastSegment = last.charAt(0).toUpperCase() + last.slice(1);
  const [step, setStep] = useState(stepCount.step1);
  const [group, setGroup] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);
  const [postTarget, setPostTarget] = useState(null);
  const [selectedGroupType, setSelectedGroupType] = useState(null);
  const [crmGroupData, setCrmGroup] = useState([]);
  const media = lastSegment === "Facebook-2" ? "Facebook" : "Instagram";

  const [socialProfile, setSocialProfile] = useState([]);
  const [storedLocale, setStoredLocale] = useState(
    localStorage.getItem("selectedLocale")
  );
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showLanguageChangeModal, setShowLanguageChangeModal] = useState(false);

  const [interval_data, setIntervalData] = useState({
    interval: null,
    norequest: null,
    search_index: null,
    stratagy: false,
    pro_stratagy: 1
  });

  const [group_data, setGroupData] = useState({
    group_id: null,
    message: null,
  });

  const [keyword_data, setKeywordsData] = useState({
    datevalue: null,
    gender: null,
    keyword: null,
    prospect: null,
    selectedinterval: null,
    post_target: null,
    // pro_convo:0,
    // pro_stratagy:1
  });



  const [action_data, setActionData] = useState({
    action: null,
  });
  const [action_data_type, setActionDataType] = useState();

  const [connectData, setConnectData] = useState({
    group_id: null,
    custom: false,
    message: null,
    interval: "30-60",
    norequest: "5",
    search_index: 1,
    action: null,
    datevalue: null,
    gender: "male",
    keyword: 0,
    prospect: "no",
    selectedinterval: "90",
    stratagy: 0,
    pro_convo: 0,
    pro_stratagy: 1,
    post_target: "Like"
  });


  function fetchInstagramDetailsFunction() {
    axios
      .get(`${BASE_URL}api/instagram/follow-followers`, {})
      .then((response) => {
        if (response?.data?.status === "success") {
          const result = response?.data;

          setUserData(result?.data);
        }
      })
      .catch((error) => {
      });
  }

  function fetchFacebookDetailsFunction() {
    axios
      .get(`${BASE_URL}api/facebook/follow-followers`, {})
      .then((response) => {
        if (response?.data?.status === "success") {
          const result = response?.data;
          setUserData(result?.data);
        }
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    if (media === "Facebook") {
      fetchFacebookDetailsFunction();
    } else if (media === "Instagram") {
      fetchInstagramDetailsFunction();
    }
  }, [media]);

  const handleStateChange = (newstate) => {
    setConnectData((prevConnectData) => ({
      ...prevConnectData,
      ...Object.entries(newstate)
        .filter(([_, value]) => value !== null && value !== undefined && value !== "{}")
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    }));
  };

  useEffect(() => {
    handleStateChange({
      ...interval_data,
      ...group_data,
      ...keyword_data,
      ...action_data,
    });
  }, [interval_data, group_data, keyword_data, action_data]);

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);

  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const renderStepContent = () => {
    switch (step) {
      case stepCount.step1:
        return (
          <FirstStep
            key={connectData?.id}
            messagesList={messageData}
            group={group}
            groupData={connectData}
            media={media}
            onChange={setGroupData}
            setAlertData={setAlertData}
            socialProfile={socialProfile?.id}
            setSelectedGroupType={setSelectedGroupType}
          />
        );

      case stepCount.step2:
        return (
          <SecondStep
            key={connectData?.id}
            onChange={setIntervalData}
            groupData={connectData}
            media={media}
          />
        );

      case stepCount.step3:
        return (
          <ThirdStep
            key={connectData?.id}
            keywordData={keywordData}
            onChange={setKeywordsData}
            selectedGroupType={selectedGroupType}
            groupData={connectData}
            media={media}
          />
        );

      case stepCount.step4:
        return (
          <ForthStep
            key={connectData?.id}
            groups={crmGroupData}
            onChange={setActionData}
            groupData={connectData}
            setActionDataType={setActionDataType}
          />
        );

      case stepCount.step5:
        return <FifthStep />;

      case stepCount.step6:
        return <SixthStep />;
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (step > stepCount.step1) {
      setStep(step - 1);
    }
  };

  const validateInputs = (t) => {



    if (step === stepCount.step2) {
      if (!connectData.norequest || connectData.norequest === NaN) {
        return t("pages.title.Please Select Number of Requests first.");
      }
    }
    if (!connectData.group_id) {
      return t("pages.title.Please select group first.");
    }
    if (!connectData.message) {
      return t("pages.title.Please select message first.");
    }

    // if(!(messageData?.find((msg)=>msg.id == connectData.message)?.id)){
    //   return "Please select message first.";
    // }

    if (!socialProfile?.id) {
      return `${t("pages.title.Please connect the")} ${media} ${t("pages.title.feature to continue.")}`;
    }

    if (step === stepCount.step3) {
      if (
        keyword_data.prospect !== "no" &&
        keyword_data?.selectedinterval === "Custom" &&
        !keyword_data.datevalue
      ) {
        return t("pages.title.Please select correct Date.");
      }
    }


    if (step === stepCount.step4) {
      const ACTIONS = JSON.parse(action_data?.action);
      if (!parseInt(ACTIONS.moveGroupId) && action_data_type !== "no") {
        return t("pages.title.Please create a CRM group first");
      }
      if (!parseInt(ACTIONS.moveStageId) && action_data_type !== "no") {
        return t("pages.title.Please create a CRM Stage first");
      }
    }
    return null;
  };

  const handleNextClick = () => {
    // Check if the message is selected
    const errorMessage = validateInputs(t);
    if (errorMessage) {
      setAlertData({
        show: true,
        message: errorMessage,
        variant: "error",
      });
      return;
    }

    // Save settings if at step 4
    if (step === stepCount.step4) {
      saveSetting();
    }

    if (step === stepCount.step5) {
      saveSetting();
    }

    // Move to the next step if not at the last step
    if (step < stepCount.step5) {
      setStep(step + 1);
    }
  };

  function fetchCrmGroupData() {
    let params = { media: media };
    fetchCrmGroups(
      params,
      (response) => {
        if (response?.data) setCrmGroup(response?.data ?? []);
      },
      (error) => { }
    );
  }



  function saveSetting() {
    let connectFindalData = connectData;

    if (connectData.keyword == 0) {
      connectFindalData.keyword = null;
    }
    let params = { media, connectData };

    createSocialTarget(
      params,
      (response) => {
        fetchTargetSettingData();
        // if (response?.data) setCrmGroup(response?.data ?? []);
      },
      (error) => { }
    );
  }
  function fetchTargetSettingData() {
    let params = {
      prospection_type: media === "Instagram" ? "instagram" : "facebook",
    };
    fetchTargetList(
      params,
      (response) => {
        const connectResponse = response?.data?.data[0] ?? [];
        if (response?.data) {
          setConnectData(connectResponse);
          setGroupData({
            group_id: connectResponse?.group_id,
            message: connectResponse?.message,
          });
        }
      },
      (error) => { }
    );
  }

  function fetchSocialProfileFeatureData() {
    setLoading(true)
    let params = { media };
    fetchSocialProfileFeature(
      params,
      (response) => {
        if (response?.data) setSocialProfile(response?.data?.data ?? []);
        setLoading(false);
      },
      (error) => {
        setLoading(false);

      }
    );
  }

  function fetchKeywordLists() {
    let params = { media: media };

    if (media == "Instagram") return true;
    fetchKeywordList(
      params,
      (response) => {
        if (response?.data?.data) {
          let keywordsResponse = response?.data?.data ?? [];
          keywordsResponse.push({ id: 0, name: "none" });
          const sortedArray = keywordsResponse.sort((a, b) => a.id - b.id);
          setKeywordData(sortedArray);
        }
      },
      (error) => { }
    );
  }

  function fetchMessageLists() {
    let params = media === "Facebook" ? JSON.stringify(['fb_prospecting']) : JSON.stringify(['ig_prospecting'])
    getAllMessagesList(
      params,
      (response) => {
        if (response?.data) setMessageData(response?.data);
      },
      (error) => { }
    );
  }

  function fetchGroupData() {
    let params = { media: media };
    fetchGroupList(
      params,
      (response) => {
        if (response?.data?.data) setGroup(response?.data?.data);
      },
      (error) => { }
    );
  }

  useEffect(() => {
    fetchGroupData();
    fetchTargetSettingData();
    fetchMessageLists();
    fetchCrmGroupData();
    fetchKeywordLists();
    fetchSocialProfileFeatureData();
  }, []);

  const englistTitleFaceBook = [
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924519885?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924524000?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924525295?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924530186?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924537174?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ];

  const frenchTitleFaceBook = [
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/950733944?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924655329?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/950734401?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/950734427?badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924669044?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ];

  const germanTitleFaceBook = [
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926248468?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926250489?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926250585?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926250655?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926253427?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ];

  const spanishTitleFaceBook = [
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964216253?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964216834?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964217277?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964217570?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964217782?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
  ];

  const englistTitleInstagram = [
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/959607373?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/959607373?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/959607479?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/959607526?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/959607572?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
  ];

  const frenchTitleInstagram = [
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/959600214?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/959600176?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/959600116?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/959600140?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/959600070?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
  ];

  const germanTitleInstagram = [
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/959618417?badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/959611922?badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/959617256?badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/959617256?badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/959611961?badge=0&autopause=0&app_id=58479",
    },
  ];

  const spanishTitleInstagram = [
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964218167?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964218685?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964218996?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964219670?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
    {
      title: "Conéctese fácilmente con nuevos prospectos",
      url: "https://player.vimeo.com/video/964219226?title=0&byline=0&portrait=0&badge=0&autopause=0&app_id=58479",
    },
  ];

  const selectedUrl = useMemo(() => {
    switch (storedLocale) {
      case "fr-FR":
        return media === "Instagram"
          ? frenchTitleInstagram[step - 1]
          : frenchTitleFaceBook[step - 1];
      case "de-DE":
        return media === "Instagram"
          ? germanTitleInstagram[step - 1]
          : germanTitleFaceBook[step - 1];
      case "es-ES":
        return media === "Instagram"
          ? spanishTitleInstagram[step - 1]
          : spanishTitleFaceBook[step - 1];

      default:
        return media === "Instagram"
          ? englistTitleInstagram[step - 1]
          : englistTitleFaceBook[step - 1];
    }
  }, [storedLocale, step, media]);

  useEffect(() => {
    window.addEventListener('nonEnglishLanguageDetected', (event) => {
      setShowLanguageChangeModal(true)
    });

    return () => {
      window.removeEventListener('nonEnglishLanguageDetected', () => { });
    };
  }, [])

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <TopHeader
        title={t("pages.title.easily_connect_with")}
        url={selectedUrl.url}
      />
      <div className="main-container" key={media}>
        <TabMenu buttonsConfig={prospectionButtonsConfig} />
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setAlertData} />
        )}
        {
          showLanguageChangeModal && (
            <Dialog open={showLanguageChangeModal} onClose={() => { setShowLanguageChangeModal(false); }} maxWidth="lg" fullWidth PaperProps={{ style: { borderRadius: "10px" } }}>
              <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ "fontFamily": "'Inter'", "fontSize": "22px", "fontWeight": "600", "lineHeight": "28px", "textAlign": "left", color: "rgba(99, 99, 99, 1)" }}>{t('languagePopUpTitle')}</span>
                <span>
                  <img src={media === "Facebook" ? "/images/facebook-eng.svg" : "/images/instagram-eng.svg"} alt="" style={{ height: "40px", width: "auto" }} />
                </span>
              </DialogTitle>
              <DialogContent onInteractOutside={(e) => { e.preventDefault(); }}>
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src={t(`languagePopUpVideoURL.${media}`) + "&autoplay=1&muted=1"} allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: "10px", boxShadow: "0px 0px 7px 2px #b5b5b5" }} title="how to"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <button style={{ width: "180px", justifyContent: "center", alignItems: "center", gap: "15px" }} className="bordered-btn bordered-btn-bg" onClick={() => { setShowLanguageChangeModal(false) }} id="start-novayla-connect-2-connect">
                  <span>{t('languagePopUpRestartButton')}</span>
                  <span><RestartAltIcon style={{ height: "20px", width: "20px" }} /></span>
                </button>
              </DialogActions>
            </Dialog>
          )
        }
        {/* {!isExtensionInstalled && (
          <>
            <div
              className="novalya-dashboard-right nova-addExtension-popup"
            >
              <div className="novalya-dashboard-rightactive">
                <div className="novalya-rightactive-text">
                  <h2 className="novlay-dash-main-hd">
                    {t("pages.title.software_status")}
                  </h2>
                  <div
                    className="novalya-rightactive-active tag active-ext"
                    style={{ display: "none" }}
                  >
                    <span>
                      <span style={{ padding: "0" }}>•</span>{" "}
                      <span>{t("pages.title.active")}</span>
                    </span>
                  </div>
                  <div className="novalya-rightactive-inactive inactive-tag inactive-ext">
                    <span>
                      <span style={{ padding: "0" }}>•</span>{" "}
                      <span>{t("pages.title.in active")}</span>
                    </span>
                  </div>
                </div>
              </div>
              <button
                className="nova-prospecting install-extension-btn inactive-tag inactive-ext"
                onClick={() => {
                  window.open(
                    "https://chromewebstore.google.com/detail/novalya/iemhbpcnoehagepnbflncegkcgpphmpc?authuser=0&hl=en",
                    "_blank"
                  );
                }}
              >
                <span>{t("pages.title.Install Extension")}</span>
                <HiArrowLongRight />
              </button>
            </div>
          </>
        )} */}
        {/* {!loading && isExtensionInstalled ? ( */}
        {/* userData ? ( */}
        <div>

          <div className="content-box">
            <div className="shadow-wraper shadow-wraper-zoom">
              <div className={`steps-bar step-done-0${step - 1}`}>
                <span className="progress-bar "></span>
                <div className="steps-item">
                  <span className="steps-number">1</span>
                  <span>{t("pages.title.who&what")}</span>
                </div>
                <div className="steps-item">
                  <span className="steps-number">2</span>
                  <span>{t("pages.title.setting")}</span>
                </div>
                <div className="steps-item">
                  <span className="steps-number">3</span>
                  <span>{t("pages.title.advance_targets")}</span>
                </div>
                <div className="steps-item">
                  <span className="steps-number">4</span>
                  <span>{t("pages.title.Action")}</span>
                </div>
              </div>
              {group.length > 0 && renderStepContent()}
              {group.length === 0 && (
                <ZeroStep
                  messagesList={messageData}
                  media={media}
                  group={group}
                />
              )}
            </div>
            <div
              className="step-btn-group"
              style={{
                display: step < 2 ? "flex" : "",
                justifyContent: step < 2 ? "flex-end" : "",
              }}
            >
              <button
                className={
                  step > 1
                    ? "bordered-btn bordered-btn-bg"
                    : "hidden bordered-btn"
                }
                onClick={handleBackClick}
              >
                {t("pages.title.back")}
              </button>

              {step < 5 ? (
                <button
                  className="bordered-btn bordered-btn-bg"
                  onClick={handleNextClick}
                >
                  {step < 4
                    ? t("pages.title.next")
                    : t("pages.title.finish")}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {/* ) : (
            <CheckProfileModal />
          ) */}
        {/* ) : null} */}
      </div>
    </>
  );
}