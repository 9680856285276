import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  List,
  ListItemIcon,
  IconButton,
  Icon,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Avatar,
  Tooltip,
  Box,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import { FaFacebookMessenger } from "react-icons/fa";

import { RgbaColorPicker } from "react-colorful";
import EditIcon from "@mui/icons-material/Edit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import FacebookIcon from "@mui/icons-material/Facebook";
import NoteIcon from "@mui/icons-material/Note";
import axios from "axios";
import jwtDecode from "jwt-decode";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import Campaign from "../campaign/campaign";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import {
  API_BASE_URL,
  fetchCrmGroups,
  fetchTaggedUsersNotes,
  fetchTaggedUsersapi,
} from "backendServices/ApiCalls";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useJumboTheme } from "@jumbo/hooks";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Group = () => {
  const [groupName, setGroupName] = useState("");
  const [color, setColor] = useState({ r: 255, g: 255, b: 255, a: 1 });
  const [savedGroups, setSavedGroups] = useState([]);
  const [taggedUser, setTaggedUser] = useState([]);
  const [stages, setStages] = useState([]);
  const [editingGroupId, setEditingGroupId] = useState(null); // Added
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null); // Added
  const [showColorPicker, setShowColorPicker] = useState(false); // Added
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [campiagnData, setCampiagnData] = useState({});
  const [showCampign, setShowCampign] = useState(false);
  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedstageId, setSelectedStageId] = useState(null);
  const [noteUser, setNoteUser] = useState({});
  const [notes, setNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editDescription, seteditdescription] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { loginUserData } = useContext(CustomProvider);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);
  const fetchTaggedUsers = async () => {
    let params = {};
    fetchTaggedUsersapi(
      params,
      (response) => {
        if (response?.data != undefined) {
          const updatedGroups = response.data.map((group) => ({ ...group }));
          setTaggedUser(updatedGroups);
        }
      },
      (error) => {}
    );
  };

  const fetchStages = async () => {
    setStages([
      {
        id: 1,
        stage_num: 1,
      },
      {
        id: 2,
        stage_num: 2,
      },
      {
        id: 3,
        stage_num: 3,
      },
    ]);
  };

  useEffect(() => {
    let isMounted = true;
    fetchTaggedUsers();
    return () => {
      isMounted = false;
    };
  }, [selectedGroupIndex]);

  useEffect(() => {
    let isMounted = true;
    fetchStages();
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        // You can access the decoded token's properties
        // ... and so on
      } catch (error) {}
    }
  }, []);
  const fetchGroupdData = async () => {
    let params = {};

    fetchCrmGroups(
      params,
      (response) => {
        if (response?.data != undefined) {
          const groupsWithColor = response.data.map((group) => ({
            ...group,
            custom_color: group.custom_color || {
              r: 255,
              g: 255,
              b: 255,
              a: 1,
            },
          }));
          setSavedGroups(groupsWithColor);
        }
      },
      (error) => {}
    );
  };
  useEffect(() => {
    let isMounted = true;

    fetchGroupdData();
    return () => {
      isMounted = false;
    };
  }, [selectedCheckboxes]);
  const handleCheckboxChange = (group, checked) => {
    if (checked) {
      setSelectedGroups([...selectedGroups, group]);
    } else {
      setSelectedGroups(
        selectedGroups.filter((groups) => groups.id !== group.id)
      );
    }
  };
  const handleToggleUser = (tag) => {
    const tagIds = tag.tag_id;
    setSelectedStageId(tag.id);
    // Split the comma-separated tag IDs into an array
    const tagIdArray = tagIds.split(",").map(Number);

    // Create a new array to store the selected groups
    const newSelectedGroups = [...selectedGroups];

    // Iterate through the tag IDs and handle them individually
    tagIdArray.forEach((tagId) => {
      // Find the group associated with the current tag ID
      const groupToSelect = savedGroups.find((group) => group.id === tagId);
      if (
        groupToSelect &&
        !newSelectedGroups.some(
          (selectedGroup) => selectedGroup.id === groupToSelect.id
        )
      ) {
        // Add the group to the newSelectedGroups array if it's not already there
        newSelectedGroups.push(groupToSelect);
      }
    });

    // Update the selectedCheckboxes state for all selected groups
    const updatedSelectedCheckboxes = { ...selectedCheckboxes };
    newSelectedGroups.forEach((group) => {
      updatedSelectedCheckboxes[group.id] = true;
    });

    // Update the state with the newSelectedGroups and updatedSelectedCheckboxes
    setSelectedGroups(newSelectedGroups);
    setSelectedCheckboxes(updatedSelectedCheckboxes);
    setShowGroupPopup(true);
  };
  const handleNoteToggle = (user) => {
    setNoteUser(user);
    fetchTaggedUsersNotes(
      {},
      (response) => {
        if (response?.data != undefined) {
          const allNotes = response.data;

          // Filter notes based on user_id
          const userNotes = allNotes.filter(
            (note) => note.fb_user_id === user.fb_user_id
          );
          setNotes(userNotes);
          // Now, userNotes contains all the notes for the selected user
        }
      },
      (error) => {}
    );
    setShowNotePopup(true);
  };

  const handleCheckboxToggle = (groupId) => {
    const groupToToggle = savedGroups.find((group) => group.id === groupId);
    setSelectedCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [groupToToggle.id]: !selectedCheckboxes[groupToToggle.id],
    }));
    handleCheckboxChange(groupToToggle, !selectedCheckboxes[groupToToggle.id]);
  };

  const handleUpdateGroups = async () => {
    // Extract just the IDs from selectedGroups
    const selectedGroupIds = selectedGroups.map((group) => group.id);

    // Convert the array of IDs into a comma-separated string
    const tagIdsString = selectedGroupIds.join(",");

    try {
      // Call the patch axios API and save the ID string to tag_id
      const response = await axios.patch(
        `${API_BASE_URL}/taggeduser/${selectedstageId}`,
        {
          tag_id: tagIdsString,
        }
      );

      if (response.data[0] === 1) {
        // Fetch updated tagged users
        fetchTaggedUsers();
      }
      setShowGroupPopup(false);
    } catch (error) {}
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleColorBoxClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  const formatColorString = (colorObj) => {
    if (typeof colorObj === "string") {
      return colorObj;
    }
    return `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, ${colorObj.a})`;
  };
  const handleColorChange = (newColor) => {
    const updatedColor = {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      a: newColor.a !== undefined && !isNaN(newColor.a) ? newColor.a : 1,
    };
    setColor(updatedColor);
  };

  const handleSaveGroup = async () => {
    if (groupName) {
      const formatColorString = (colorObj) => {
        if (typeof colorObj === "string") {
          return colorObj;
        } else {
          return `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, ${
            colorObj.a || 1
          })`;
        }
      };

      const newGroup = {
        name: groupName,
        custom_color: formatColorString(color),
        user_id: 1,

        class: "warning",
        order_num: 0,
        total_user: 0,
        is_primary: 0,
      };

      if (editingGroupId !== null) {
        // Update the UI immediately
        const updatedGroups = [...savedGroups];
        updatedGroups[selectedGroupIndex] = {
          ...updatedGroups[selectedGroupIndex],
          name: groupName,
          custom_color: formatColorString(color),
        };
        setSavedGroups(updatedGroups);
        try {
          const response = await axios.patch(
            `${API_BASE_URL}/group/${editingGroupId}`,
            newGroup
          );

          // Optionally, you can show a success message here

          const updatedstage = response.data.find((item) => item === 1);
          if (updatedstage) {
            fetchGroupdData();
          }
          // Clear the form fields and state
          setSelectedGroupIndex(null);
          setEditingGroupId(null);
          setGroupName("");
          setColor({ r: 255, g: 0, b: 0, a: 1 });
          setShowColorPicker(false);
        } catch (error) {
          // Optionally, you can show an error message here
        }
      } else {
        axios
          .post(`${API_BASE_URL}/group`, newGroup)
          .then((response) => {
            // Update the UI immediately with the newly created group
            setSavedGroups([...savedGroups, response.data]);

            // Optionally, you can show a success message here

            // Clear the form fields and state
            setGroupName("");
            setColor({ r: 255, g: 0, b: 0, a: 1 });
            setShowColorPicker(false);
          })
          .catch((error) => {
            // Optionally, you can show an error message here
          });
      }
    }
  };

  const handleDeleteTaggedUser = async (taggedUserId) => {
    try {
      await axios.delete(`${API_BASE_URL}/taggeduser/${taggedUserId}`);
      const updatedTaggedUsers = taggedUser.filter(
        (user) => user.id !== taggedUserId
      );
      setTaggedUser(updatedTaggedUsers);
    } catch (error) {}
  };
  const handleEditGroup = (index) => {
    const selectedGroup = savedGroups[index];
    setEditingGroupId(selectedGroup.id);
    setGroupName(selectedGroup.name);
    setColor(selectedGroup.custom_color);
  };
  const handleToggleUserList = (groupId) => {
    if (expandedGroup === groupId) {
      setExpandedGroup(null); // Collapse the group if it's already expanded
    } else {
      setExpandedGroup(groupId); // Expand the group
    }
  };

  const handleDeleteGroup = async (index) => {
    const confirmed = window.confirm(t("pages.title.delete_data"));
    if (confirmed) {
      const groupToDelete = savedGroups[index];
      try {
        await axios.delete(`${API_BASE_URL}/group/${groupToDelete.id}`);
        const updatedGroups = [...savedGroups];
        updatedGroups.splice(index, 1);
        setSavedGroups(updatedGroups);
      } catch (error) {}
    }
  };
  const handleCampiagn = (group) => {
    setCampiagnData(group);
    setShowCampign(true);
  };
  const getListStyle = (isDraggingOver, custom_color) => ({
    background: isDraggingOver ? "#dbdbdb" : "#efecec",
    padding: 10,
    width: "20rem",
    minWidth: "20rem",
    boxShadow: isDraggingOver ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.3)",
    borderTop: `2px solid ${custom_color}`,
  });
  const getItemStyle = (isDragging, draggingStyle) => ({
    userSelect: "none",
    padding: 16,
    margin: "0 0 8px 0",
    background: "white",
    ...draggingStyle,
    color: "black",
    boxShadow: isDragging ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.3)",
  });
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const destGroupId = result.destination.droppableId.replace("droppable", "");

    const draggedUserId = parseInt(result.draggableId);

    // Update the taggedUser array by removing the dragged user
    const updatedTaggedUser = taggedUser.filter(
      (user) => user.id !== draggedUserId
    );
    // Update the state with the updated arrays
    setTaggedUser(updatedTaggedUser);
    setSavedGroups([...savedGroups]);

    // Update the backend using axios.patch
    axios
      .patch(`${API_BASE_URL}/taggeduser/${draggedUserId}`, {
        stage_id: destGroupId,
      })
      .then(async (response) => {
        const updatedstage = response.data.find((item) => item === 1);
        if (updatedstage) {
          fetchTaggedUsers();
        }
      })
      .catch((error) => {});
  };
  const doesTagIdMatch = (tag_id, targetNumber) => {
    let tagIdsArray = [];
    if (tag_id.includes(",")) {
      tagIdsArray = tag_id.split(",").map(Number);
    } else {
      tagIdsArray = [tag_id];
    }

    return tagIdsArray.includes(String(targetNumber));
  };
  const [description, setdescription] = useState("");

  const handleNoteChange = (value) => {
    setdescription(value);
  };

  const handleSaveNote = () => {
    // Define the note data object
    const noteData = {
      user_id: noteUser.user_id, // Assuming noteUser contains the selected user's data
      description: description, // Replace with the actual description you want to save
      fb_user_id: noteUser?.fb_user_id, // Replace with the actual user IDs
    };

    // Make a POST request to create a new note
    axios
      .post(`${API_BASE_URL}/note`, noteData)
      .then((response) => {
        // Handle successful creation of the note

        // Clear the description field
        setdescription("");

        // Retrieve all notes after successfully creating a new note
        axios
          .get(`${API_BASE_URL}/note`)
          .then((notesResponse) => {
            const allNotes = notesResponse.data;

            // Filter notes based on user_id
            const userNotes = allNotes.filter(
              (note) => note.fb_user_id === noteUser.fb_user_id
            );
            setNotes(userNotes);

            // Optionally, you can perform any additional actions after updating the notes state
          })
          .catch((notesError) => {
            // Handle errors if fetching notes fails
          });
      })
      .catch((error) => {});
  };

  const handleupdateNote = (note) => {
    const noteData = {
      user_id: noteUser?.user_id,
      description: editDescription,
      fb_user_id: noteUser?.fb_user_id, // Replace with actual user IDs
    };

    if (note.id) {
      // If noteId is provided, it means we're updating an existing note
      axios
        .patch(`${API_BASE_URL}/note/${note.id}`, noteData)
        .then((response) => {
          // Handle successful update of the note

          // Optionally, you can fetch all notes again to update the notes list
          axios
            .get(`${API_BASE_URL}/note`)
            .then((notesResponse) => {
              const allNotes = notesResponse.data;
              const userNotes = allNotes.filter(
                (note) => note.fb_user_id === noteUser?.fb_user_id
              );
              setNotes(userNotes);
              setEditingNoteId(null);
              setdescription("");
            })
            .catch((notesError) => {});
        })
        .catch((error) => {});
    }
    setIsEditing(false); // Exit edit mode
  };
  const handleDeleteNote = (notes) => {
    const apiUrl = `${API_BASE_URL}/note/${notes.id}`;

    // Send a DELETE request
    axios
      .delete(apiUrl)
      .then((response) => {
        // Handle the success response, e.g., show a success message or update the UI.
        // Optionally, you can fetch all notes again to update the notes list
        axios
          .get(`${API_BASE_URL}/note`)
          .then((notesResponse) => {
            const allNotes = notesResponse.data;
            const userNotes = allNotes.filter(
              (note) => note.fb_user_id === notes.fb_user_id
            );
            setNotes(userNotes);
            setEditingNoteId(null);
            setdescription("");
          })
          .catch((notesError) => {});
      })
      .catch((error) => {
        // Handle any errors that occurred during the DELETE request.
      });
  };
  // Filter and map the taggedUser array

  useEffect(() => {
    setFilteredUsers(
      taggedUser
        .filter((user) => doesTagIdMatch(user.tag_id, expandedGroup?.id))
        .map((item, index) => item)
    );
  }, [expandedGroup, taggedUser]);

  return (
    <div style={{ height: "100%" }}>
      {!showCampign && (
        <div>
          {" "}
          <Paper
            elevation={3}
            style={{ padding: "2rem", width: "100%", height: "100%" }}
          >
            <Typography variant="h4" marginBottom="1rem">
              {t("pages.title.add_group")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Grid container flexDirection="column" gap={2}>
                <Grid
                  container
                  flexDirection="row"
                  gap={2}
                  xs={12}
                  alignItems="center"
                  paddingBottom={1}
                >
                  <Grid>
                    <TextField
                      label={t("pages.title.add_group")}
                      value={groupName}
                      onChange={handleGroupNameChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid>
                    <ListItemIcon
                      onClick={handleColorBoxClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        style={{ border: "1px solid grey", padding: ".2rem" }}
                      >
                        <div
                          style={{
                            width: "2rem",
                            height: "1.2rem",
                            border: "1px solid grey",
                            backgroundColor: formatColorString(color),
                          }}
                        />
                      </div>
                    </ListItemIcon>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveGroup}
                    >
                      {editingGroupId !== null
                        ? t("pages.title.update")
                        : t("pages.title.save")}
                    </Button>
                    {showColorPicker && (
                      <RgbaColorPicker
                        color={color}
                        onChange={handleColorChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {expandedGroup !== null && (
                <Button
                  variant="contained"
                  id="sync_fbname"
                  value={expandedGroup?.id}
                  color="primary"
                >
                  Sync
                </Button>
              )}
            </Box>
            {storedLocale === "fr-FR" ? (
              <>
                <Icon color="primary">
                  <VideoIcon />
                </Icon>{" "}
                <a
                  href="https://player.vimeo.com/video/867897933?badge=0&autopause=0&player_id=0&app_id=58479"
                  target="_blank"
                  rel="noreferrer"
                >
                  VOIR LA VIDEO EXPLICATIVE
                </a>
              </>
            ) : (
              <>
                <Icon color="primary">
                  <VideoIcon />
                </Icon>{" "}
                <a
                  href="https://player.vimeo.com/video/867931966?badge=0&autopause=0&player_id=0&app_id=58479"
                  target="_blank"
                  rel="noreferrer"
                >
                  Watch Tutorial Video
                </a>
              </>
            )}
            <List height="100%">
              <Typography variant="h4" margin="1rem 0">
                {t("pages.title.group_list")}
              </Typography>
              <div style={{ display: "flex", gap: "1.5rem" }}>
                <div
                  style={{ width: "30%", overflowX: "auto", height: "30rem" }}
                >
                  {savedGroups.map((group, index) => (
                    <div
                      key={index}
                      data-id={index}
                      style={{
                        border: "1px solid #ccc",
                        padding: "1rem",
                        borderRadius: "5px",
                        marginBottom: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            width: "80%",
                            alignItems: "flex-end",
                          }}
                          onClick={() => handleToggleUserList(group)}
                        >
                          <Avatar
                            sx={{
                              cursor: "pointer",
                              backgroundColor: group.custom_color || "blue",
                              color:
                                group.custom_color === "rgba(255, 255, 255, 1)"
                                  ? "black"
                                  : "white",
                              marginRight: "8px",
                            }}
                          >
                            {group.name.charAt(0)}{" "}
                          </Avatar>
                          <Typography noWrap variant="h3">
                            {group.name}
                          </Typography>
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title="Campaign">
                            <CampaignIcon
                              onClick={() => handleCampiagn(group)}
                            />
                          </Tooltip>
                          <Tooltip title="Edit">
                            <EditIcon onClick={() => handleEditGroup(index)} />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              onClick={() => handleDeleteGroup(index)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {expandedGroup !== null && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        overflow: "auto",
                        height: "30rem",
                        width: "70%",
                      }}
                    >
                      {stages.map((stage) => {
                        return (
                          <Droppable
                            droppableId={`droppable${stage.stage_num}`}
                            key={stage.id}
                          >
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(
                                  snapshot.isDraggingOver,
                                  stage.custom_color
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="h3">
                                    Stage {stage.stage_num}
                                  </Typography>
                                </div>
                                <div style={{ marginTop: "3rem" }}>
                                  {filteredUsers
                                    .filter(
                                      (taguser) =>
                                        taguser.stage_id === stage.stage_num
                                    )
                                    .map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={`${item.id}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <p style={{ margin: 0 }}>
                                                {" "}
                                                {item.fb_name}
                                              </p>

                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: "0.6rem",
                                                }}
                                              >
                                                <IconButton
                                                  style={{ padding: 0 }}
                                                  onClick={() =>
                                                    handleToggleUser(item)
                                                  }
                                                >
                                                  <OpenWithIcon
                                                    style={{
                                                      color: "#008000",
                                                    }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  style={{ padding: 0 }}
                                                  href={`https://facebook.com/${item.fb_user_id}`}
                                                  target="_blank"
                                                >
                                                  <FacebookIcon
                                                    style={{
                                                      color: "#1877F2",
                                                    }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  style={{ padding: 0 }}
                                                  href={`https://www.messenger.com/t/${item.fb_user_id}`}
                                                  target="_blank"
                                                >
                                                  <FaFacebookMessenger
                                                    style={{
                                                      color: "#1877F2",
                                                    }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  style={{ padding: 0 }}
                                                  onClick={() =>
                                                    handleNoteToggle(item)
                                                  }
                                                >
                                                  <NoteIcon
                                                    style={{
                                                      color: "#e7c9a9",
                                                    }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  style={{ padding: 0 }}
                                                  onClick={() =>
                                                    handleDeleteTaggedUser(
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon
                                                    style={{
                                                      color: "#FF0000",
                                                    }}
                                                  />
                                                </IconButton>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                </div>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        );
                      })}
                    </div>
                  </DragDropContext>
                )}
              </div>
            </List>
          </Paper>
          {showGroupPopup && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: "translate(-50%, -50%)",
                background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7),rgba(0, 0, 0,0.7))`,
                padding: "1rem",
                zIndex: 9999,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: "#fff",
                    width: "30rem",
                    padding: "1rem",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <h2>Update Groups</h2>

                  <FormControl component="fieldset" style={{ width: "100%" }}>
                    <FormGroup>
                      {savedGroups.map((group) => (
                        <FormControlLabel
                          key={group.id}
                          control={
                            <Checkbox
                              checked={
                                selectedCheckboxes[group.id] !== undefined &&
                                selectedCheckboxes[group.id]
                              }
                              onChange={() => handleCheckboxToggle(group.id)}
                              name={group.name}
                            />
                          }
                          label={group.name}
                        />
                      ))}
                    </FormGroup>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "1.5rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateGroups}
                      >
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setShowGroupPopup(false);
                          setSelectedGroups([]);
                          setSelectedCheckboxes([]);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          <BootstrapDialog
            onClose={() => {
              setShowNotePopup(false);
              setNoteUser({});
              setNotes([]);
            }}
            aria-labelledby="customized-dialog-title"
            open={showNotePopup}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {t("pages.title.note_for")} {noteUser.fb_name}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowNotePopup(false);
                setNoteUser({});
                setNotes([]);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <textarea
                value={description}
                style={{
                  width: "100%",
                  minHeight: "100px",
                  margin: "1rem 0",
                  padding: "0.5rem",
                  border: "1px solid #ccc",
                  outline: "none",
                }}
                placeholder={t("pages.title.enter_note")}
                onChange={(e) => handleNoteChange(e.target.value)}
              ></textarea>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveNote}
                >
                  {t("pages.title.Add Note")}
                </Button>
              </div>
              {notes.map((note, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                  key={index}
                >
                  {editingNoteId === note.id ? (
                    <textarea
                      onChange={(e) => seteditdescription(e.target.value)}
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        margin: "1rem 0",
                        padding: "0.5rem",
                        border: "1px solid #ccc",
                        outline: "none",
                      }}
                      placeholder={t("pages.title.enter_note")}
                    ></textarea>
                  ) : (
                    <>
                      <textarea
                        value={note.description}
                        style={{
                          width: "100%",
                          minHeight: "100px",
                          margin: "1rem 0",
                          padding: "0.5rem",
                          border: "1px solid #ccc",
                          outline: "none",
                        }}
                        readOnly
                      ></textarea>
                      <p>
                        {note.createdAt
                          ? new Date(note.createdAt).toLocaleString("en-US", {
                              hour12: true,
                            })
                          : ""}
                      </p>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {editingNoteId === note.id ? (
                      <IconButton onClick={() => handleupdateNote(note)}>
                        <CheckCircleOutlineOutlined
                          style={{ color: "#00FF00" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setEditingNoteId(note.id)}>
                        <EditIcon style={{ color: "#808080" }} />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => handleDeleteNote(note)}
                      disabled={editingNoteId === note.id}
                    >
                      <DeleteIcon style={{ color: "#FF0000" }} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </DialogContent>
          </BootstrapDialog>
        </div>
      )}

      {showCampign && <Campaign selectedgroup={campiagnData} />}
    </div>
  );
};

export default Group;
