import React, { useEffect, useState } from 'react';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboOverlay from "@jumbo/components/JumboOverlay";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


const CityBgCard = (props) => {
    const { t } = useTranslation();

    const [language, setLanguage] = useState('en')
    let frenchObj = {
        'line1': "Bienvenue à Novalya",
        'line2': "Plans d'abonnement"
    }

    const defautLanguageFun = () => {
        const defaultLanguage = navigator.language;
        const wordsArray = defaultLanguage?.split("-");
        if (wordsArray?.length > 0) {
            setLanguage(wordsArray[0])
        }
    }

    useEffect(() => {
        defautLanguageFun()
    }, [])
    return (
        <JumboCardQuick
            noWrapper
            bgImage={getAssetPath(`${ASSET_IMAGES}/wall/plans.jpg`, "680x180")}
            // bgImage={https://jumbo.g-axon.work/images/cities-bg.png}
            sx={{ height: 125 }}
        >
            <JumboOverlay margin={24} opacity={0} vAlign={"center"} sx={{ color: 'common.white' }}>
                <Stack direction={"row"} spacing={3} justifyContent={"space-around"} alignItems={"center"}>

                    <Div className={"Stack-item"} sx={{ display: 'flex' }}>
                        {/* <CloudOutlinedIcon sx={{ fontSize: '80px', mr: 3 }} /> */}
                        <img src={`${ASSET_IMAGES}/novalya-white.png`} style={{ width: '60px' }} alt="novalya" />


                        <div style={{ marginTop: '10px', marginLeft: '5px' }}>

                        </div>
                    </Div>


                    <Div className={"Stack-item"} sx={{ display: 'flex' }}>
                        {/* <CloudOutlinedIcon sx={{ fontSize: '80px', mr: 3 }} /> */}
                        <div>

                        </div>
                    </Div>


                    <div className={"Stack-item"}>
                        <Typography
                            variant={"h2"}
                            fontSize={"20px"}
                            color={"inherit"}
                            mb={.5}
                            sx={{ '& sup': { position: 'relative', fontSize: '60%', top: '-10px' } }}
                        >
                            {
                                language === 'en' ? t('pages.title.novalya') : frenchObj.line1
                            }
                        </Typography>
                        <Typography variant={"body1"} fontSize={"12px"}>
                            {
                                language === 'en' ? t('pages.title.plansubscription') : frenchObj.line2
                            }
                        </Typography>
                        {/* {
                            props?.page === 'plans' ?
                                <Typography variant={"body1"} fontSize={"12px"}>{t('pages.title.plansubscription')}</Typography>
                                :
                                <Typography variant={"body1"} fontSize={"12px"}>{t('pages.title.paymentinformation')}</Typography>
                        } */}
                    </div>

                </Stack>
            </JumboOverlay>
        </JumboCardQuick>
    );
};

export default CityBgCard;
