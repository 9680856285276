import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import ContentHeader from "../../../layouts/shared/headers/ContentHeader";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Badge from "@mui/material/Badge";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useState } from "react";
import { updateProfilePicture } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState(null);
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [fileName, setFileName] = useState('');
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        setFileName(selectedFile.name);
        setImage(selectedFile);
      } else {
        setalertData({
          show: true,
          message: t("pages.title.Invalid file type. Please select an image file"),
          variant: "error",
        });
        setFileName(t("pages.title.No file chosen"));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnIsLoading(true);
    setIsButtonDisabled(true);
    if (!image) {
      setalertData({
        show: true,
        message: t("pages.title.Invalid file type. Please select an image file"),
        variant: "error",
      });
      setBtnIsLoading(false);
      setIsButtonDisabled(false);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result; // Extract base64 encoded string

      const formData = new FormData();
      formData.append("image", base64Data);
      if (!image) {
        setalertData({
          show: true,
          message: t("pages.title.Invalid file type. Please select an image file"),
          variant: "error",
        });
        setBtnIsLoading(false);
        setIsButtonDisabled(false);
        return;
      } else {
        updateProfilePicture(
          formData,
          (response) => {
            setBtnIsLoading(false);
            setIsButtonDisabled(false);
            if (response?.data?.status === "error") {
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: "error",
              });
            } else if (response?.data?.status === "success") {
              setAnchorEl(null);
              setalertData({
                show: true,
                message: t("pages.title.Profile picture updated successfully"),
                variant: "success",
              });
              setloginUserData((prevState) => ({
                ...prevState,
                profilepictureurl: response?.data?.pictureurl,
              }));
            } else {
              setalertData({
                show: true,
                message: t("pages.title.Something went wrong please try again later"),
                variant: "error",
              });
            }
          },
          (error) => {
            setBtnIsLoading(false);
            setIsButtonDisabled(false);
            setalertData({
              show: true,
              message: t("pages.title.Something went wrong please try again later"),
              variant: "error",
            });
          }
        );
      }
    };

    reader.readAsDataURL(image);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;
  useEffect(() => {
    setFileName(t("pages.title.No file chosen"));
  }, [t]);

  
  return (
    <ContentHeader
      avatar={
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <React.Fragment>
              <CameraAltIcon
                sx={{ color: "inherit", fontSize: "1.5rem" }}
                aria-describedby={id}
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    border: 1,
                    borderColor: "divider",
                  },
                }}
              >
                {alertData.show ? (
                  <SweetAlert
                    alertData={alertData}
                    setalertData={setalertData}
                  />
                ) : (
                  ""
                )}
                <Div sx={{ p: 3, pb: 2, minWidth: 276 }}>
                  <Div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Avatar
                      sx={{ width: 60, height: 60, mr: 2 }}
                      alt={userData.firstname}
                      src={userData.profilepictureurl}
                    />
                    <Div sx={{ flex: "1 1 auto" }}>
                      <Typography variant={"h5"} mb={0.35}>
                        {userData.firstname}
                      </Typography>
                      {/* <Typography
                                            variant={"body1"}
                                            color={"text.secondary"}
                                        >Life must be big</Typography> */}
                    </Div>
                  </Div>
                  <List>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      encType="multipart/form-data"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        "& .MuiTextField-root": { width: "34ch" },
                      }}
                      alignItems="center"
                    >
                      <ListItem sx={{ px: 0, pb: 0 }}>
                        <ListItemText
                          primary={
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#f5f9ff',
                              borderRadius: '8px',
                              padding: '8px 12px',
                              width: 'fit-content'
                            }}>
                              <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="upload-file"
                                type="file"
                                onChange={handleUpload}
                              />
                              <label htmlFor="upload-file">
                                <Button
                                  variant="outlined"
                                  component="span"
                                  sx={{
                                    backgroundColor: '#ffffff',
                                    borderColor: '#d0d0d0',
                                    color: '#000000',
                                    textTransform: 'none',
                                    padding: '5px 10px',
                                    '&:hover': {
                                      backgroundColor: '#f0f0f0',
                                      borderColor: '#c0c0c0',
                                    },
                                  }}
                                >
                                  {t("pages.title.Choose File")}
                                </Button>
                              </label>
                              <Typography
                                variant="body2"
                                sx={{
                                  marginLeft: 1,
                                  color: '#7d7d7d',
                                  maxWidth: '150px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {fileName || t("pages.title.No file chosen")}
                              </Typography>
                              <IconButton
                                sx={{
                                  marginLeft: '20px',
                                  backgroundColor: '#00c853',
                                  '&:hover': { backgroundColor: '#00b94a' },
                                }}
                                size="small"
                              >
                                <CameraAltIcon sx={{ color: '#ffffff', fontSize: '20px' }} />
                              </IconButton>
                            </div>
                          }
                        />
                      </ListItem>

                      <Button
                        style={{ width: "50%", marginTop: 20 }}
                        variant="contained"
                        type="submit"
                        disabled={isButtonDisabled}
                      >
                        <span>
                          {isBtnLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            t("pages.title.update") 
                          )}
                        </span>
                      </Button>
                    </Box>
                  </List>
                </Div>
              </Popover>
            </React.Fragment>
          }
          sx={{
            width: 100,
            mr: 2,
            "& .MuiBadge-badge": {
              height: 35,
              width: 35,
              minWidth: 16,
              overflow: "hidden",
              border: 1,
              borderColor: "common.white",
              color: "common.white",
              bgcolor: "primary.main",
              cursor: "pointer",
              right: 12,
              bottom: 12,
            },
          }}
        >
          <Avatar
            sx={{ width: 100, height: 100 }}
            alt={userData.firstname}
            src={userData.profilepictureurl}
          />
        </Badge>
      }
      sx={{
        position: "relative",
        zIndex: 1,

        "& .MuiCardHeader-action": {
          alignSelf: "center",
        },
      }}
    />
  );
};

export default Header;
