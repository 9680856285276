import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List } from "@mui/material";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { addReseller } from "backendServices/ApiCalls";
import { useState } from "react";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { t } from "i18next";

const validationSchema = yup.object({
  companyName: yup.string().required("Company name is required"),
  adminPassword: yup
    .string()
    .required("Confirm password is required")
    .min(8, t("pages.title.Password must be at least 8 characters long"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()+^])[A-Za-z\d@$!%*?&()+^]+$/,
      "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character allowed charecters are @$!%*?&()+^"
    ),
  firstName: yup.string().required("First name is required"),
  email: yup.string().required("Email is required"),
});

const FormComponent = () => {
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleSubmit = (data, setSubmitting) => {
    addReseller(
      data.companyName,
      data.firstName,
      data.lastName,
      data.email,
      data.phoneNumber,
      data.companyAddress,
      data.adminUsername,
      data.adminPassword,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {}
    );
  };
  return (
    <JumboCardQuick title="Add new User" noWrapper>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding sx={{ mb: 2 }}>
        <Formik
          validateOnChange={true}
          initialValues={{
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            companyAddress: "",
            adminUsername: "",
            adminPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            handleSubmit(data, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Div
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "16px",
                }}
              >
                <JumboTextField
                  fullWidth
                  name="companyName"
                  label="Company Name"
                />
                <JumboTextField fullWidth name="firstName" label="First Name" />
                <JumboTextField fullWidth name="lastName" label="Last Name" />
                <JumboTextField fullWidth name="email" label="Email Address" />
                <JumboTextField
                  fullWidth
                  name="phoneNumber"
                  label="Phone Number"
                />
                <JumboTextField
                  fullWidth
                  name="companyAddress"
                  label="Address of the Company"
                />
                <JumboTextField
                  fullWidth
                  name="adminUsername"
                  label="Username for Admin Account"
                />
                <JumboTextField
                  fullWidth
                  name="adminPassword"
                  label="Admin Password"
                  type="password"
                />
              </Div>
              <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default FormComponent;
