import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import { countries } from "app/pages/components/mui/AutoCompletes/data";
import { useContext } from "react";
import { getCustomerPortal } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ChargebeePortal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [cbInstance, setCbInstance] = React.useState(null);
  const [portalUrl, setPortalUrl] = React.useState("");
  const { loginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const handlePortal = async () => {
    let params = {
      customerid: loginUserData.customerid,
    };

    await cbInstance.setPortalSession(function () {
      // We will discuss how to implement this endpoint below.
      setLoader(true);

      return new Promise(function (resolve, reject) {
        // This sampe response should be fetched as a result of an ajax call.
        getCustomerPortal(
          params,
          (response) => {
            setLoader(false);
            if (response?.data?.status === "error") {
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: "error",
              });
            } else if (response?.data?.status === "success") {
              var sampleResponse = {
                id: response?.data?.data?.portal_session?.id,
                token: response?.data?.data?.portal_session?.token,
                access_url: response?.data?.data?.portal_session?.access_url,
                status: "created",
                created_at: response?.data?.data?.portal_session?.created_at,
                expires_at: response?.data?.data?.portal_session?.expires_at,
                object: "portal_session",
                customer_id: response?.data?.data?.portal_session?.customer_id,
              };
              resolve(sampleResponse);
            } else {
              setLoader(false);
              reject();
              setalertData({
                show: true,
                message: t("pages.title.Something went wrong please try again later"),
                variant: "error",
              });
            }
            setLoader(false);
          },
          (error) => {
            reject(error);
          }
        );
      });
    });

    let cbPortal = await cbInstance.createChargebeePortal();
    await cbPortal.open({
      close() {
        //close callbacks
      },
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    script.async = true;
    script.onload = () => {
      // Chargebee script has loaded, you can now use it
      const instance = window.Chargebee.init({
        site: "novalya",
      });
      setCbInstance(instance);
      // Rest of your Chargebee code here
    };

    // Append the script to the document
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [userData, countries]);
  if (userData?.firstname === "") {
    return <div>laoding</div>;
  }

  return (
    <>
      <div className="profile-page-left02">
        <p className="profile-billing">{t("pages.title.updateprofile")}</p>
        <button className="profile-left-btn" onClick={() => handlePortal()}>
          {loader ? (
            <CircularProgress size={24} />
          ) : (
            t("pages.title.chargebeeportal")
          )}
        </button>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
      >
        <DialogContent sx={{ padding: 0 }}>
          <iframe
            src={portalUrl}
            width="350"
            height="700"
            title="Embedded Content"
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close Dialog</Button>
        </DialogActions>
      </Dialog>

      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
    </>
  );
};

export default ChargebeePortal;
