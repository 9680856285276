import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import {
  fetchResellerList,
  deleteReseller,
  updateResellerStatus,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useModalState } from "app/hooks/use-modal-state";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

const ResellerList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [status, setStatus] = React.useState({});
  const navigate = useNavigate();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [selectedRows, setSelectedRows] = React.useState([]);

  let params = {};
  const FetchResellerData = () => {
    fetchResellerList(
      params,
      (response) => {
        setLoading(false);
        if (response?.data?.data) setGroupData(response?.data?.data ?? []);
      },
      (error) => {}
    );
  };
  function handleDelete() {
    deleteReseller(
      { deleteId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Deleted Successfully")}`,
          variant: "delete",
        });
        deleteModal.onClose();
        FetchResellerData();
      },
      (error) => {}
    );
  }

  const handleStatusChange = (resellerId, newStatus) => {
    // Update the status in the local state
    setStatus((prevStatus) => ({
      ...prevStatus,
      [resellerId]: newStatus,
    }));

    // Update the status in the backend
    updateResellerStatus(
      resellerId,
      newStatus,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  const { loginUserData } = useContext(CustomProvider);
  const onWhitelist = () => {};
  const deleteModal = useModalState();
  useEffect(() => {
    FetchResellerData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const gridDesign = {
    "& .MuiDataGrid-main": {
      height: "320px",
    },
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
    "& .MuiDataGrid-columnHeaders": {
      background: "#ffffff",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    "& .MuiDataGrid-row": {
      marginTop: 2,
      background: "#ffffff",
      borderRadius: 2,
    },
    "& .MuiDataGrid-footerContainer": {
      background: "#ffffff",
    },
    "& .MuiTextField-root": {
      position: "absolute",
      right: 30,
      top: -55,
    },
  };

  const columns = [
    {
      field: "company_name",
      headerName: "Company Name",
      dataGeneratorUniquenessEnabled: true,
      width: 250, // Adjust the width as needed
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.company}
        </div>
      ),
    },
    {
      field: "first_name",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 250, // Adjust the width as needed
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.firstname}
        </div>
      ),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.lastname}
        </div>
      ),
    },
    // {
    //   field: "comments",
    //   headerName: "#Comments",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) => (params.row.comments ? params.row.comments : 0),
    // },
    // {
    //   field: "messages",
    //   headerName: "# Messages",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) =>
    //   params.row.messages ? params.row.messages : 0,
    // },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.email}
        </div>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.mobile}
        </div>
      ),
    },
    {
      field: "company_address",
      headerName: "Address of Company",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.address1}
        </div>
      ),
    },
    {
      field: "username",
      headerName: "Username for the admin",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.username}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <>
          {" "}
          <IconButton aria-label="delete" style={{ color: "red" }}>
            <DeleteIcon
              onClick={() => {
                setDeleteId(params.row.id);
                deleteModal.onOpen();
              }}
            />
          </IconButton>
          <IconButton aria-label="edit" style={{ color: "red" }}>
            <EditIcon
              onClick={() => navigate(`/edit-gray-label/${params.row.id}`)}
            />
          </IconButton>
          <>
            <Switch
              defaultChecked={params.row.status === "Approved"}
              onChange={(event) =>
                handleStatusChange(params.row.id, event.target.checked ? 1 : 0)
              }
            />
          </>
        </>
      ),
    },

    // {
    //   field: "tier",
    //   headerName: "Tier",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) =>
    //     params.row.tier ? params.row.tier : "Tier1",
    // },
    // {
    //   field: "has_conversection",
    //   headerName: "Has Conversection",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150, // Adjust the width as needed
    //   renderCell: (params) =>
    //     params.row.has_conversection === 0 ? (
    //       <Chip label="Yes" color="success" variant="outlined" />
    //     ) : (
    //       <Chip label="No" color="secondary" variant="outlined" />
    //       )

    // },
    // {
    //   field: "status",
    //   headerName: "Is Active",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150, // Adjust the width as needed
    //   renderCell: (params) =>
    //     params.row.status === 0 ? (
    //       <Chip label="Yes" color="success" variant="outlined" />
    //     ) : (
    //       <Chip label="No" color="secondary" variant="outlined" />
    //     ),
    // },
  ];

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <JumboDemoCard
        title="Gray lable Users"
        sx={{
          borderRadius: "0%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        wrapperSx={{
          backgroundColor: "background.paper",
          pt: 0,
          borderRadius: 0,
        }}
      >
        <Box sx={{ width: 1, position: "relative" }}>
          <Box sx={{ position: "relative", top: -12 }}></Box>
          <Button variant="contained" component={Link} to="/new-gray-label">
            Add New
          </Button>
        </Box>
        <Dialog
          onClose={deleteModal.onClose}
          open={deleteModal.isOpen}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              position: "relative",
            }}
          >
            <Button
              onClick={deleteModal.onClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                minWidth: 0,
                padding: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 18L18 6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>

            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
              <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
              <path d="M32 22V21.2C32 20.0799 32 19.5198 31.782 19.092C31.5903 18.7157 31.2843 18.4097 30.908 18.218C30.4802 18 29.9201 18 28.8 18H27.2C26.0799 18 25.5198 18 25.092 18.218C24.7157 18.4097 24.4097 18.7157 24.218 19.092C24 19.5198 24 20.0799 24 21.2V22M26 27.5V32.5M30 27.5V32.5M19 22H37M35 22V33.2C35 34.8802 35 35.7202 34.673 36.362C34.3854 36.9265 33.9265 37.3854 33.362 37.673C32.7202 38 31.8802 38 30.2 38H25.8C24.1198 38 23.2798 38 22.638 37.673C22.0735 37.3854 21.6146 36.9265 21.327 36.362C21 35.7202 21 34.8802 21 33.2V22" stroke="#EF0E0E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "#6b7280",
                  marginTop: "4px",
                }}
              >
                {t("pages.title.delete_data")}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={deleteModal.onClose}
              sx={{
                borderRadius: 2,
                height: 40,
                backgroundColor: "#D3D3D3",
                color: "#000000",
                boxShadow: "none",
                border: "1px solid  #D3D3D3",
                "&:hover": {
                  backgroundColor: "#000000",
                  color: "#D3D3D3",
                  boxShadow: "none",
                  border: "1px solid  #D3D3D3",
                },
              }}
            >
              {t("pages.title.cancel")}
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              sx={{
                borderRadius: 2,
                height: 40,
                boxShadow: "none",
                border: "1px solid  #41CE81",
                "&:hover": {
                  boxShadow: "none",
                  border: "1px solid  #41CE81",
                },
              }}
            >
              {t("pages.title.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </JumboDemoCard>
      <DataGrid
        rows={groupData}
        columns={columns}
        slots={{ toolbar: QuickSearchToolbar }}
        sx={gridDesign}
        hideScrollbar={true}
      />
    </>
  );
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        px: 0.5,
        py: 1.5,
        "& .MuiInputBase-root": {
          "&:after": {
            border: "none",
          },
        },
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

export default ResellerList;
