import React, { useContext, useEffect, useState } from 'react'
import './transformerplan.css'
import logo from "../../../assets/img/logo-plan.svg"
import courseIcon from "../../../assets/img/course-icons.svg"
import courseVid from "../../../assets/img/courseVideoicon.png"
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault'
import { germanEventCharge } from 'backendServices/ApiCalls'
import SweetAlert from '../components/mui/Alerts/SweetAlert'
import { useLocation, useNavigate } from 'react-router-dom'
import { Terms } from '../upgradepriceplan/termData'
import LocalizationOptions from 'app/shared/JumboCustomizer/components/LocalizationOptions'
import {useTranslation} from "react-i18next";


const TransformerPlan = () => {
const [loading, setLoading] = useState(false)
const [isTime, setIsTime] = useState(true)
const [URL, setUrl] = useState("https://player.vimeo.com/video/1039401182?badge=0&autopause=0&player_id=0&app_id=58479")
const { loginUserData } = useContext(CustomProvider);
 const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refId = queryParams.get('uname')
  const time = queryParams.get('time')
  const currLang = queryParams.get('lang')
  const selectedLang = localStorage.getItem("selectedLocale")
const {t} = useTranslation();
 
  
  const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });
    const navigate = useNavigate()

	useEffect(() => {
		if(currLang?.includes("de")){
			localStorage.setItem('selectedLocale',"de-DE")
            //  setUrl("https://vimeo.com/1063794317?badge=0&autopause=0&player_id=0&app_id=58479")
			 setUrl("https://player.vimeo.com/video/1063794317?badge=0&autopause=0&player_id=0&app_id=58479")
        }
		else{
            localStorage.setItem('selectedLocale',"fr-FR")
			setUrl("https://player.vimeo.com/video/1039401182?badge=0&autopause=0&player_id=0&app_id=58479")
			 
		}
		if(time == "20"){
			setIsTime(true)
		}else{
			setIsTime(false)

		}
	},[])

	useEffect(() => {
		if(selectedLang === "de-DE"){
		
            //  setUrl("https://vimeo.com/1063794317?badge=0&autopause=0&player_id=0&app_id=58479")
			 setUrl("https://player.vimeo.com/video/1063794317?badge=0&autopause=0&player_id=0&app_id=58479")
        }
		else{
			setUrl("https://player.vimeo.com/video/1039401182?badge=0&autopause=0&player_id=0&app_id=58479")
			 
		}
		
	},[selectedLang])

    let targetDate = new Date(); // UTC equivalent
	targetDate.setMinutes(targetDate.getMinutes() + 20);
	let goTime = localStorage.getItem('go_time');
	let is_gt = new Date() > new Date(goTime)
	
	if(!goTime || is_gt){
        localStorage.setItem('go_time',targetDate);
    }else{
		goTime=new Date(goTime)
		targetDate=goTime
	}

      function calculateTimeLeft() {
        const now = new Date();
		if(!time){
			targetDate = new Date();
			targetDate.setUTCHours(23, 0, 0, 0); // Set time to 11:00 PM UTC
			
			// Convert to UTC+1 (12:00 AM in that timezone)
			targetDate = new Date(targetDate.getTime() + 60 * 60 * 1000); 
		}
	
		
        const difference = targetDate - now;
    
        // Calculate days, hours, minutes, and seconds left
        const timeLeft = {
          days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
            2,
            "0"
          ),
          hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
            2,
            "0"
          ),
          minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
            2,
            "0"
          ),
          seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
        };
       if(!difference ){
		setIsTime(false);
	   }
        return difference > 0
          ? timeLeft
          : { days: "00", hours: "00", minutes: "00", seconds: "00" };
      }
    
      // State to keep track of the remaining time
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      // Update the countdown every second
      useEffect(() => {
    localStorage.setItem("plans", JSON.stringify(Terms));
	refId? localStorage.setItem("referralId", refId):localStorage.setItem("referralId", "SONNY")

        const timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearInterval(timer); // Clear timer on component unmount
      }, []);

	  useEffect(() => {
		// Check if the script is already added
		if (document.querySelector(`script[src="https://event.webinarjam.com/t/sale/cookie.js"]`)) {
		  console.log("Script already exists, skipping injection.");
		  return;
		}
	
		const script = document.createElement("script");
		script.src = "https://event.webinarjam.com/t/sale/cookie.js";
		script.async = true;
		script.defer = true;
	
		script.onload = () => {
			window.wj_sales = {
				webinar_id: "roolri9",
				
			  };
			  console.log("WebinarJam sales tracking data set:", window.wj_sales);
		};
	
		script.onerror = (error) => {
		  console.error("Error loading script:", error);
		};
	
		document.body.appendChild(script);
	
		return () => {
		  if (script.parentNode) {
			script.parentNode.removeChild(script);
			console.log("Script removed on unmount.");
		  }
		};
	  }, []);


       const handleBuy = async () => {

        let regionCode = localStorage.getItem("regionCode")
        if (!regionCode) {
            const res = await (await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306')).json()
            regionCode = res?.continent_code;
            localStorage.setItem('regionCode', regionCode)
        }

        if (!loginUserData?.customerid) {
            localStorage.setItem("toTransform","True")
            let plan =   "Formation-Leads-en-RDV-Qualifies-Basic-Plan-EUR-Monthly"
            localStorage.setItem("planId",plan);
            localStorage.setItem("backto","/go")
	        localStorage.setItem("fromOffers", "true");

            navigate("/signup?coupon_code=Course-LTC")
            return
        }
              setLoading(true)
             
      //chageBee calling
              let params = {
                  customerId: loginUserData?.customerid,
                  currency:"EUR",
                  itemPriceId:"Formation-Sonny-Novalya-Transformer-vos-leads-en-RDV-qualifies-EUR"
      
              };
              germanEventCharge(
                  params,
                  (response) => {
                      if (response.data.status === "success") {
      
                          window.location = response?.data?.data?.url
                      } else {
                          setalertData({
                              show: true,
                              message: "Something Went Wrong, Please Try Again",
                              variant: "error",
                          });
                          setLoading(false)
      
                      }
                  },
                  (error) => {
                      setLoading(false)
      
                      setalertData({
                          show: true,
                          message: error?.response?.data?.message,
                          variant: "error",
                      });
                  }
              );
          };

        const  alreadyHaveAccount = ()=>{
            localStorage.setItem("toTransform","True")
            navigate("/login")
        }

	console.log("loginUserData?.customerid", loginUserData?.customerid)
  return (
    <>
     {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
        <div class="course-main">
		<div class="course-header">
			<div class="course-container">
				<div class="course-header-warp">
					<img class="course-head-img" src="/images/novalya-blue.png" alt="logo" />
				</div>
				<div style={{ width: "170px" }}><LocalizationOptions /></div>
			</div>
		</div>

		<div class="course-video-wrap">
			<div class="course-container">
        <div className='connect_name'>{loginUserData?.firstname? `You are connected as ${loginUserData?.firstname}`:""}</div>

				<div class="course-videoInner">
					<div class="courserLeft">
						<img class="courseVideoicon" src={courseVid}/>
						<div class="courserVideoWrap">
							<iframe class="course-videos" src={URL} title="Video" allowfullscreen="">
							</iframe>
							
						</div>
					</div>
                   
					<div class="courserRight">
						<span class="course-title">   {t("go.Convertissez Vos Leads en Clients")}  <br></br>
              {t("go.La Méthode Imparable pour Obtenir des Rendez-Vous Qualifiés")}
            </span>
						<span class="priceOld"> {t("go.Prix Normal")} <span>1188€</span></span>
						<span class="priceNew">{t("go.Prix Spécial")} <span>100€ </span></span>
					<div class="offerDuration">
							<span>{t("go.L'offre se termine dans")}:</span>
							<div id="countdown">
								<div id='tiles'>
                 
                    <span>{timeLeft.hours}</span>
                    <span>{timeLeft.minutes}</span>
                    <span>{timeLeft.seconds}</span>
                  </div>
								<div class="labels">
									
									<li>{t("go.Heures")}</li>
									<li>{t("go.Minutes")}</li>
									<li>{t("go.Secondes")}</li>
								</div>
							</div>
						</div>
						<button class="course-buy-button" onClick={handleBuy}>{t("go.Achetez Maintenant")} </button>
                        <div>{!loginUserData?.firstname?<div className='already_account' onClick={()=>alreadyHaveAccount()} > {t("go.Avez-vous déjà un compte")}  </div>:""}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="course-module">
			<div class="module-container">
				<span class="course-title">{t("go.Programme")} :</span>
				<div class="module-wraper">
					<div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Module inx",{index:"1"})} :</span>
							<p>{t("go.La Psychologie du Lead")}</p>
						</div>
					</div>
					<div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Module inx",{index:"2"})}:</span>
							<p>{t("go.Structurer un Entonnoir de Conversion")}</p>
						</div>
					</div>
					<div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Module inx",{index:"3"})}:</span>
							<p>{t("go.Scripts de Communication et Suivi")}</p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Module inx",{index:"4"})}:</span>
							<p>{t("go.Optimisation et Analyse")} </p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Module inx",{index:"5"})}:</span>
							<p> {t("go.Sécuriser les rendez-vous et réduire les absences")} </p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Module inx",{index:"6"})}:</span>
							<p>{t("go.L’Art de Démarrer un Rendez-Vous Impactant")} </p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">{t("go.Bonus")} { !loginUserData?.customerid && "#1"}</span>
							<p>{t("go.Le replay des 2 jours de Challenge Facebook")}</p>
						</div>
					</div>

					{
							  loginUserData?.customerid
							  ? null
							  : <>
									  <div class="module-items">
										  <div class="module-items-icon">
											  <img src={courseIcon} />
										  </div>
										  <div class="module-item-text">
											  <span class="module-title">{t("go.Bonus")} #2*</span>
											  <p>{t("go.2 mois d’abonnement Novalya offerts !")} </p>
										  </div>
									  </div>
									  <div class="module-items">
										  <div class="module-items-icon">
											  <img src={courseIcon} />
										  </div>
										  <div class="module-item-text">
											  <span class="module-title">{t("go.Bonus")} #3*</span>
											  <p>{t("go.Accompagnement de démarrage Novalya offert")} </p>
										  </div>
									  </div>
									  <span class="course-foot">*{t("go.Pour les Nouveaux Utilisateurs Uniquement")} </span>
							  </>
					}
          

		     <div style={{display:"flex",justifyContent:"center" ,width:"100%", paddingTop:"20px"}}><button class="course-buy-button" onClick={handleBuy}>{t("go.Achetez Maintenant")}</button></div>
				</div>
			</div>
		</div>

		<footer class="course-footer">
			<div class="course-container-foot">
				<div class="course-footer-wrap">
					<p class="course-footer-txt">Copyright   |  Novalya  © 2023-2024</p>
				</div>
			</div>
		</footer>
	</div>
    </>
  )
}

export default TransformerPlan