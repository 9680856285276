import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import {
  fetchSectionList,
  deleteSection,
  duplicateSection,
} from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import { useModalState } from "app/hooks/use-modal-state";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import "./section.css";
import {
  CustomPagination,
  Datatable,
} from "app/pages/components/mui/Datatable";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { libraryButtonsConfig } from "./LibraryTabData";
import { GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid";
import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";

const SectionList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sectionData, setSectionData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [duplicateId, setDuplicateId] = useState(null);
  const apiRef = useGridApiRef();

  const navigate = useNavigate();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  let params = {};
  const FetchSectionData = () => {
    fetchSectionList(
      params,
      (response) => {
        setLoading(false);
        setSectionData(response?.data?.data);
      },
      (error) => {}
    );
  };
  const deleteModal = useModalState();
  const duplicateModal = useModalState();
  useEffect(() => {
    FetchSectionData();
  }, []);

  function handleDelete() {
    deleteSection(
      { deleteId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Deleted Successfully")}`,
          variant: "delete",
        });
        deleteModal.onClose();
        FetchSectionData();
      },
      (error) => {}
    );
  }

  function handleDuplicate() {
    duplicateSection(
      { duplicateId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Duplicated Successfully")}`,
          variant: "success",
        });
        duplicateModal.onClose();
        FetchSectionData();
      },
      (error) => {}
    );
  }
  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const columns = [
    {
      field: "section",
      headerName: "Name",
      dataGeneratorUniquenessEnabled: true,
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: 36,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => navigate(`/edit/sections/${params.row.id}`)}
              sx={{
                height: 36,
                ml: 2,
                backgroundColor: "#D1EDE5",
                color: "#007867",
                border: "solid 1px #007867",
                "&:hover": {
                  backgroundColor: "#007867",
                  color: "#D1EDE5",
                  border: "solid 1px #D1EDE5",
                },
              }}
              variant="outlined"
            >
              <EditCalendarIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.edit")}
            </Button>
            <Button
              onClick={() => {
                setDuplicateId(params.row.id);
                duplicateModal.onOpen();
              }}
              sx={{
                height: 36,
                pl: 3,
                pr: 3,
                ml: 2,
                backgroundColor: "#D1EFF6",
                color: "#006C9C",
                border: "solid 1px #006C9C",
                "&:hover": {
                  backgroundColor: "#006C9C",
                  color: "#D1EFF6",
                  border: "solid 1px #D1EFF6",
                },
              }}
              variant="outlined"
            >
              <FileCopyOutlinedIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.duplicate")}
            </Button>
            <Button
              color="error"
              onClick={() => {
                setDeleteId(params.row.id);
                deleteModal.onOpen();
              }}
              sx={{
                height: 36,
                ml: 2,
                pr: 3,
                backgroundColor: "#FAE0DB",
                color: "#FF0202",
                border: "solid 1px #FF0202",
                "&:hover": {
                  backgroundColor: "#FF0202",
                  color: "#FAE0DB",
                  border: "solid 1px #D1EFF6",
                },
              }}
              variant="outlined"
            >
              <DeleteOutlineOutlinedIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.delete")}
            </Button>
          </Box>
        );
      },
    },
  ];

  function QuickSearchToolbar() {
    const [showActionMenu, setShowActionMenu] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState("");

    const handleSort = (field, order) => {
      const sortModel = [
        {
          field,
          sort: order,
        },
      ];
      apiRef.current.setSortModel(sortModel);
      if (order === null) {
        setSelectedMenuItem("");
      } else {
        setSelectedMenuItem(order === "asc" ? "Ascending" : "Descending");
      }
      setShowActionMenu(null);
    };

    useEffect(() => {
      const sortModel = apiRef.current.getSortModel();
      if (sortModel.length > 0) {
        const order = sortModel[0].sort;
        setSelectedMenuItem(order === "asc" ? "Ascending" : "Descending");
      } else {
        setSelectedMenuItem("");
      }
    }, [apiRef]);

    return (
      <Box
        sx={{
          px: 0.5,
          py: 1.5,
          "& .MuiInputBase-root": {
            "&:after": {
              border: "none",
            },
          },
          display: "flex",
        }}
      >
        <GridToolbarQuickFilter className="custom-search-class-nova" />
      </Box>
    );
  }

  return (
    <>
      <div className="library-tabs">
        <TopHeader
          title={t("pages.title.Your_Message_Sections")}
          url={t("pages.title.section_url")}
        />
        <TabMenu buttonsConfig={libraryButtonsConfig} />
      </div>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Typography sx={{ color: "#170F49" }} variant="h2"></Typography>
      <div className="nova-container">
        <div className="nova-box library-box-only library-section">
          <Box className="nova-btn-card btn-group-friends-list">
            <Button
              variant="contained"
              onClick={() => navigate("/create/sections")}
              sx={{
                backgroundColor: "#22C55E",
                color: "white",
                "&:hover": {
                  backgroundColor: "#22C55E",
                  color: "white",
                },
              }}
              startIcon={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.21098 8.51251L8.21098 11.0624C8.21116 11.3453 7.9814 11.5751 7.69849 11.5749C7.41548 11.5748 7.1861 11.3455 7.1861 11.0624L7.1861 8.51252L4.63619 8.51252L4.63614 8.51252C4.35304 8.51234 4.12363 8.28302 4.1236 7.99998L8.21098 8.51251ZM8.21098 8.51251L10.7609 8.51251C11.0442 8.51251 11.2737 8.28314 11.2733 7.99998C11.2733 7.717 11.0439 7.48765 10.7609 7.48765L8.21098 7.48765V4.93773C8.21098 4.65468 7.98156 4.42533 7.69844 4.42515H7.69839C7.41536 4.42515 7.18596 4.65455 7.18596 4.93758L7.18596 7.48749L4.63609 7.48749C4.3532 7.48731 4.12345 7.71704 4.1236 7.99994L8.21098 8.51251ZM12.7013 2.9973C9.93839 0.234358 5.45871 0.234358 2.69577 2.9973C-0.0673305 5.7604 -0.0671737 10.2399 2.69577 13.0029C5.45886 15.766 9.93823 15.766 12.7013 13.0029C15.4643 10.2399 15.4644 5.7604 12.7013 2.9973ZM3.41081 12.2878C1.05215 9.92917 1.06159 6.08105 3.42057 3.72207C5.77938 1.36326 9.61775 1.36326 11.9766 3.72207C14.3354 6.08087 14.3354 9.91926 11.9766 12.2781C9.61761 14.637 5.76946 14.6465 3.41081 12.2878Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.15"
                  ></path>
                </svg>
              }
            >
              {t("pages.title.create_Section")}
            </Button>
          </Box>
          <Datatable
            apiRef={apiRef}
            className="nova-table-ctm"
            rows={sectionData}
            columns={columns}
            slots={{
              toolbar: QuickSearchToolbar,
              pagination: CustomPagination,
            }}
          />
        </div>
      </div>
      <Dialog
        onClose={deleteModal.onClose}
        open={deleteModal.isOpen}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            position: "relative",
          }}
        >
          <Button
            onClick={deleteModal.onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              minWidth: 0,
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 6L18 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 18L18 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>

          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
            <path d="M32 22V21.2C32 20.0799 32 19.5198 31.782 19.092C31.5903 18.7157 31.2843 18.4097 30.908 18.218C30.4802 18 29.9201 18 28.8 18H27.2C26.0799 18 25.5198 18 25.092 18.218C24.7157 18.4097 24.4097 18.7157 24.218 19.092C24 19.5198 24 20.0799 24 21.2V22M26 27.5V32.5M30 27.5V32.5M19 22H37M35 22V33.2C35 34.8802 35 35.7202 34.673 36.362C34.3854 36.9265 33.9265 37.3854 33.362 37.673C32.7202 38 31.8802 38 30.2 38H25.8C24.1198 38 23.2798 38 22.638 37.673C22.0735 37.3854 21.6146 36.9265 21.327 36.362C21 35.7202 21 34.8802 21 33.2V22" stroke="#EF0E0E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginTop: "6px"
              }}
            >
              {t("pages.title.Delete Group")}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "#6b7280",
                marginTop: "4px",
              }}
            >
              {t("pages.title.delete_data")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={deleteModal.onClose}
            sx={{
              borderRadius: 2,
              height: 40,
              backgroundColor: "#D3D3D3",
              color: "#000000",
              boxShadow: "none",
              border: "1px solid  #D3D3D3",
              "&:hover": {
                backgroundColor: "#000000",
                color: "#D3D3D3",
                boxShadow: "none",
                border: "1px solid  #D3D3D3",
              },
            }}
          >
            {t("pages.title.cancel")}
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleDelete}
            sx={{
              borderRadius: 2,
              height: 40,
              boxShadow: "none",
              border: "1px solid  #41CE81",
              "&:hover": {
                boxShadow: "none",
                border: "1px solid  #41CE81",
              },
            }}
          >
            {t("pages.title.delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={duplicateModal.isOpen}
        onClose={duplicateModal.onClose}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            position: "relative",
          }}
        >
          <Button
            onClick={duplicateModal.onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              minWidth: 0,
              marginTop: "4px",
              marginRight: "8px",
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 6L18 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 18L18 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>

          <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="26" cy="26" r="26" fill="#F4F7FF" />
            <circle cx="26" cy="26" r="19" fill="#E1E7FF" />
            <path d="M26.0526 30.5455H19.7895C19.3149 30.5455 18.8597 30.3539 18.5241 30.0129C18.1885 29.6719 18 29.2095 18 28.7273V17.8182C18 17.336 18.1885 16.8735 18.5241 16.5325C18.8597 16.1916 19.3149 16 19.7895 16H30.5263V17.8182H19.7895V28.7273H26.0526V26.9091L29.6316 29.6364L26.0526 32.3636V30.5455ZM33.2105 34.1818V21.4545H23.3684V26.9091H21.5789V21.4545C21.5789 20.9723 21.7675 20.5099 22.1031 20.1689C22.4387 19.8279 22.8938 19.6364 23.3684 19.6364H33.2105C33.6851 19.6364 34.1403 19.8279 34.4759 20.1689C34.8115 20.5099 35 20.9723 35 21.4545V34.1818C35 34.664 34.8115 35.1265 34.4759 35.4675C34.1403 35.8084 33.6851 36 33.2105 36H23.3684C22.8938 36 22.4387 35.8084 22.1031 35.4675C21.7675 35.1265 21.5789 34.664 21.5789 34.1818V32.3636H23.3684V34.1818H33.2105Z" fill="#3C61F2" />
          </svg>

          <Box>
            {/* Header */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginTop: "6px"
              }}
            >
              Duplicate Data
            </Typography>

            {/* Subtitle */}
            <Typography
              variant="body2"
              sx={{
                color: "#6b7280", // Optional: Gray color for subtitle
                marginTop: "4px",
              }}
            >
              {t("pages.title.duplicate_data")}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogActions>
          <Button
            color="success"
            variant="contained"
            onClick={handleDuplicate}
            sx={{
              borderRadius: 2,
              height: 40,
              boxShadow: "none",
              border: "1px solid  #41CE81",
              "&:hover": {
                boxShadow: "none",
                border: "1px solid  #41CE81",
              },
            }}
          >
            {t("pages.title.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SectionList;
