import "./observer/patchResizeObserver";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import "./app/config/i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5ST8N5TC",
};

TagManager.initialize(tagManagerArgs);
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const userId = urlParams.get("userId");
const lang = urlParams.get("lang");
if (token && userId) {
  
  const expirationYears = 10; // 10 years
  const expirationDate = new Date();
  expirationDate.setFullYear(
    expirationDate.getFullYear() + expirationYears
  );

  document.cookie = `authToken=${token}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = `user_id=${userId}; expires=${expirationDate.toUTCString()}; path=/`;
  localStorage.setItem('auth-token', token)
  localStorage.setItem('token', token)
  localStorage.setItem('selectedLocale', lang)
  const curr = window.location.href.split('?')
  window.location.href=curr[0]
}
  

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
