import React from "react";

import { ASSET_IMAGES } from "../../../utils/constants/paths";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link as MyLink } from "react-router-dom";
import useApp from "app/hooks/useApp";
import { useTranslation } from "react-i18next";
import { requestPasswordReset } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import "../Login/assets/css/style.css";
const validationSchema = yup.object({
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const { t } = useTranslation();
  var { companyName } = useApp();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [serverError, setServerError] = React.useState(null);
  const [emailSuccess, setEmailSuccess] = React.useState(false);

  const resetPassword = (email, setSubmitting, resetForm) => {
    requestPasswordReset(
      email,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setServerError(response?.data?.message);

          setSubmitting(false);
          setEmailSuccess(true);
          resetForm();
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
      }
    );
  };

  const initialValues = {
    email: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        resetPassword(data.email, setSubmitting, resetForm);
      },
    });

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
     
      {emailSuccess ? <ForgotPasswordSuccess />:
        <section className="login-main">
        <div className="grid-container">
          <div className="left-panel forget-panel">
            <div className="logo">
                <img src={`${ASSET_IMAGES}/novalya-white.svg`} alt="logo" />
            </div>
          </div>
          <div className="login-wraper">
            <h3 className="login-text"> {t("pages.title.forgotpasword")}</h3>
            <form onSubmit={handleSubmit}>
              <label className="login-label"> {t("pages.title.email")}</label>
              <div className="input-group">
                <input
                  type="text"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <p className="text-danger">{errors.email}</p>
                ) : null}
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    d="M12.598 6.71753L17.8762 11.9958L17.1271 12.7444L11.8489 7.46612L12.598 6.71753ZM5.4414 6.74029L6.18999 7.48888L0.948814 12.7301L0.200226 11.9836L5.4414 6.74029Z"
                    fill="#989898"
                  />
                  <path
                    d="M8.99993 9.7117C8.20581 9.7117 7.35875 9.39406 6.7764 8.75876L0.158752 2.19406L0.899929 1.45288L7.51758 8.07053C8.36464 8.91759 9.68816 8.91759 10.5352 8.07053L17.1529 1.45288L17.894 2.19406L11.2235 8.81171C10.6411 9.39406 9.79405 9.7117 8.99993 9.7117Z"
                    fill="#989898"
                  />
                  <path
                    d="M16.4118 14.0001H1.58824C0.688235 14.0001 0 13.3118 0 12.4118V1.82359C0 0.923587 0.688235 0.235352 1.58824 0.235352H16.4118C17.3118 0.235352 18 0.923587 18 1.82359V12.4118C18 13.3118 17.3118 14.0001 16.4118 14.0001ZM1.58824 1.29418C1.27059 1.29418 1.05882 1.50594 1.05882 1.82359V12.4118C1.05882 12.7295 1.27059 12.9412 1.58824 12.9412H16.4118C16.7294 12.9412 16.9412 12.7295 16.9412 12.4118V1.82359C16.9412 1.50594 16.7294 1.29418 16.4118 1.29418H1.58824Z"
                    fill="#989898"
                  />
                </svg>
              </div>
              
              <input
                className="submit-btn"
                type="submit"
                name=""
                value={t("pages.title.submit")}
              />
              <p className="login-bottom">
                {t("pages.title.forgot_note")}
                <MyLink to="/login"> {t("pages.title.login")}</MyLink>
              </p>
            </form>
          </div>
        </div>
      </section>}
      
    </>
  );
};

export default ForgotPassword;
