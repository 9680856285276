import React, { useEffect, useContext } from "react";
import { enUS, frFR, esES,deDE,ptPT } from "@mui/material/locale";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import "./local.css";
import { useLocation } from "react-router-dom";

const LocalizationOptions = ({ setAnchorEl }) => {
  const { i18n, t } = useTranslation();
  const { setMuiLocale } = useJumboTheme();
  const { setCurrentLanguage } = useContext(CustomProvider);
  const location = useLocation();
  const path = location.pathname

  const all_languages = [
    {
      label: `${t("pages.title.english")}`,
      locale: "en-US",
      muiLocale: enUS,
      img: "/images/flag/usa.png",
    },
    {
      label: `${t("pages.title.french")}`,
      locale: "fr-FR",
      muiLocale: frFR,
      img: "/images/flag/french.png",
    },
    {
      label: `${t("pages.title.german")}`,
      locale: "de-DE",
      muiLocale: deDE,
      img: "/images/flag/germany.png",
    },
    {
      label: `${t("widgets.title.Spanish")}`,
      locale: "es-ES",
      muiLocale: esES,
      img: "/images/flag/spanish.png",
    },
    {
      label: `${t("widgets.title.Portuguese")}`,
      locale: "pt-PT",
      muiLocale: ptPT,
      img: "/images/flag/portugal.png",
    },
  ];

  const go_languages = [
   
    {
      label: `${t("pages.title.french")}`,
      locale: "fr-FR",
      muiLocale: frFR,
      img: "/images/flag/french.png",
    },
    {
      label: `${t("pages.title.german")}`,
      locale: "de-DE",
      muiLocale: deDE,
      img: "/images/flag/germany.png",
    }
  ];

  const languages = path === "/go" ? go_languages:all_languages

  const [activeLocale, setActiveLocale] = React.useState(languages[0]);
  const storedLocale = localStorage.getItem("selectedLocale");


  useEffect(() => {
    // Load the selected language from localStorage on component mount
    setCurrentLanguage(storedLocale);
    if (storedLocale) {
      const storedLanguage = languages.find(
        (language) => language.locale === storedLocale
      );
      if (storedLanguage) {
        setActiveLocale(storedLanguage);
        setMuiLocale(storedLanguage.muiLocale);
        i18n.changeLanguage(storedLanguage.locale);
      }
    }
  }, [storedLocale]);



  const handleChange = (event) => {
    const localeIndex = languages.findIndex(
      (language) => language.locale === event.target.value
    );
    if (localeIndex !== -1) {
      i18n.changeLanguage(languages[localeIndex].locale).then(() => {
        setActiveLocale(languages[localeIndex]);
        setMuiLocale(languages[localeIndex].muiLocale);
        // Save the selected language to localStorage
        localStorage.setItem("selectedLocale", languages[localeIndex].locale);
        setCurrentLanguage(languages[localeIndex].locale);
      });

      if (setAnchorEl) setAnchorEl(null);
    }
  };

  return (
    <FormControl fullWidth className="custom-language-cs">
      {/* <InputLabel id="customizer-select-locale-label">
        {t("pages.title.languagelabel")}
      </InputLabel> */}
      <Select
        size={"small"}
        labelId="select-locale"
        id="customizer-select-locale"
        value={activeLocale?.locale}
        // label="Language"
        onChange={handleChange}
        sx={{
          "#customizer-select-locale": {
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        {languages.map((language) => (
          <MenuItem key={language?.locale} value={language?.locale}>
            <img
              src={language?.img}
              alt={language?.label}
              style={{ marginRight: "8px", height: "32px", width: "32px" }}
            />
            <span>{language.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocalizationOptions;
