import React, { useEffect, useState } from "react";
import "./offers.css";
import logo from "../../../../assets/img/pricing-logo.png";
// import exclusiveImg from "../../../../assets/img/blkFri-img.png";
import exclusiveImg from "../../../../assets/img/cybFri-img.png";

import { useNavigate,useLocation } from 'react-router-dom';
import { Terms } from "./termData";
import { useTranslation } from "react-i18next";
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";

const Offers = () => {
  const { t, i18n } = useTranslation();

  const targetDate = new Date("2024-12-02T22:59:00Z"); // UTC equivalent
  const [currency, setCurrency] = useState("$");
  const [currentTF, setCurrentTF] = useState("quater");
  const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const refId = queryParams.get('uname');

  const navigate = useNavigate();
  // Function to calculate the remaining time
  function calculateTimeLeft() {
    const now = new Date();
    const difference = targetDate - now;

    // Calculate days, hours, minutes, and seconds left
    const timeLeft = {
      days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      ),
      hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
        2,
        "0"
      ),
      minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
        2,
        "0"
      ),
      seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
    };

    return difference > 0
      ? timeLeft
      : { days: "00", hours: "00", minutes: "00", seconds: "00" };
  }

  // State to keep track of the remaining time
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Update the countdown every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Clear timer on component unmount
  }, []);

  useEffect(() => {
    getCountry();
    localStorage.setItem("plans", JSON.stringify(Terms));
	  refId &&  localStorage.setItem("referralId", refId)
    navigate('/plans')
  }, []);

  const getCountry = async () => {
    const langList = [
      { title: "en", value: "en-US" },
      { title: "fr", value: "fr-FR" },
      { title: "de", value: "de-DE" },
      { title: "es", value: "es-ES" },
      { title: "pt", value: "pt-PT" },
    ];
    let regionCode = localStorage.getItem("regionCode");
    if (!regionCode) {
      const res = await (
        await fetch(
          "https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306"
        )
      ).json();

      regionCode = res?.continent_code;
      const lang = res?.languages?.split(",")?.[0]?.split("-")?.[0];

      const selectedLang =
        langList.find(
          (data) => data?.title.toUpperCase() === lang?.toUpperCase()
        )?.value || "en-US";

      i18n.changeLanguage(lang);
      localStorage.setItem("selectedLocale", selectedLang);
      localStorage.setItem("regionCode", regionCode);
    }

    setCurrency(regionCode?.toUpperCase() === "EU" ? "€" : "$");
  };

  const handleBuyNow = (plan) => {
    
 
    localStorage.setItem("planId", plan);
    localStorage.setItem("fromOffers", "true");
    navigate("/signup");
  };

  return null

//   return (
//     <>
//       <div>
//         <div className="exclusive-header">
//           <div class="aug-hd-logo">
//             <div className="container">
//               <div>
//                 <img src={logo} />
//               </div>
//               <div style={{ width: "170px" }}>
//                 <LocalizationOptions />
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* <div class="blkFri-banner">
//           <div class="blkFri-container">
//             <div class="blkFri-bannerInr">
//               <div class="blkFri-bnr-img">
//                 <img src={exclusiveImg} />
//               </div>
//               <div class="blkFri-bnr-text">
//                 <h2> {t("pricing.66_off")}</h2>
//                 <p>
//                   {" "}
// 				  {t("pricing.Offer Ends")}<br /> {t("pricing.November_dat")} 
//                 </p>
//                 <div id="countdown" class="exclusive-count">
//                   <div id="tiles">
//                     <span>{timeLeft.days}</span>
//                     <span>{timeLeft.hours}</span>
//                     <span>{timeLeft.minutes}</span>
//                     <span>{timeLeft.seconds}</span>
//                   </div>
//                   <div class="blkFri-labels">
//                     <span>{t("pricing.Days")}</span>
//                     <span>{t("pricing.Hours")}</span>
//                     <span>{t("pricing.Minutes")}</span>
//                     <span>{t("pricing.Seconds")}</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div> */}

//         <div class="cybFri-banner">
// 		<div class="cybFri-container">
// 			<div class="cybFri-bannerInr">
// 				<div class="cybFri-bnr-img">
//         <img src={exclusiveImg} />
// 				</div>
// 				<div class="cybFri-bnr-text">
// 					<h2> {t("pricing.66_off")}</h2>
// 					<p> {t("pricing.Offer Ends")} <br/> {t("pricing.mon_dat")} </p>
// 					<div id="countdown" class="exclusive-count">
// 						<div id="tiles"> <span>{timeLeft.days}</span>
//                     <span>{timeLeft.hours}</span>
//                     <span>{timeLeft.minutes}</span>
//                     <span>{timeLeft.seconds}</span></div>
// 						<div class="cybFri-labels">
//             <span>{t("pricing.Days")}</span>
//                     <span>{t("pricing.Hours")}</span>
//                     <span>{t("pricing.Minutes")}</span>
//                     <span>{t("pricing.Seconds")}</span>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	</div>

//         <div class="blkFri-plansWraper">
//           <div class="blkFri-container">
//             <div class="blkFri-warpper">
//               <input
//                 class="radio"
//                 id="blkFri-month"
//                 name="group"
//                 type="radio"
//                 checked={currentTF === "quater"}
//               />
//               <input
//                 class="radio"
//                 id="blkFri-year"
//                 name="group"
//                 type="radio"
//                 checked={currentTF === "annual"}
//               />
//               <div class="blkFri-tabs">
//                 <label
//                   class="blkFri-tab"
//                   id="blkFri-tab1"
//                   for="blkFri-month"
//                   onClick={() => setCurrentTF("quater")}
//                 >
//                   3 {t("pricing.months")}
//                 </label>
//                 <label
//                   class="blkFri-tab"
//                   id="blkFri-tab2"
//                   for="blkFri-year"
//                   onClick={() => setCurrentTF("annual")}
//                 >
//                   12 {t("pricing.months")}
//                 </label>
//               </div>
//               <div class="blkFri-panels">
//                 {currentTF === "quater" ? (
//                   <div class="blkFri-panel">
//                     <div class="blkFri-plans-wraper">
//                       <div class="blkFri-plans-main ">
//                         <div class="blkFri-plans">
//                           <div class="blkFri-plans-head">
//                             <span class="blkFri-fs-28">{t("pages.title.Basic")}</span>
//                           </div>
//                           <span class="blkFri-fs-20">{t("pricing.No commitment")}</span>
//                           <ul class="blkFri-list">
//                             <li>
//                               {"1200 " +
//                                 t("pricing.Facebook Messages per month")}
//                             </li>
//                             <li>
//                               {"800 " +
//                                 t("pricing.Instagram Messages per month")}
//                             </li>
//                             <li>
//                               {"800 " + t("pricing.AI Credits per month")}
//                             </li>
//                             <li>{"10 " + t("pricing.Tags + Pipelines")}</li>
//                             <li class="blkFri-gray-check">
//                               {t("pricing.Live chat with real humanss")}
//                             </li>
//                           </ul>
//                           <div class="blkFri-priceMain">
//                             <span class="blkFri-price-cut">{currency}291</span>
//                             <span class="blkFri-price">
//                               {currency}97<sub>/ quarter</sub>
//                             </span>
//                           </div>
//                           <span class="blkFri-gray-text">
// 						  {t("pricing.Then only amount billed quarterly",{currency:currency,amount:231})}
//                             {/* Then only {currency}756 billed quarterly */}
//                           </span>
//                           <span class="blkFri-plan-btn" onClick={()=>handleBuyNow(currency === "$"? "Special-Offer-Black-Friday-Basic-Novalya-USD-Every-3-months":"Special-Offer-Black-Friday-Basic-Novalya-EUR-Every-3-months")}>{t("pages.title.select-this-offer")}</span>
//                         </div>
//                         <div class="blkFri-plans">
//                           <div class="blkFri-plans-head">
//                             <span class="blkFri-fs-28">{t("pages.title.Business")}</span>
//                           </div>
//                           <span class="blkFri-fs-20">{t("pricing.No commitment")}</span>
//                           <ul class="blkFri-list">
// 						  <li>
//                               {"2400 " +
//                                 t("pricing.Facebook Messages per month")}
//                             </li>
//                             <li>
//                               {"1600 " +
//                                 t("pricing.Instagram Messages per month")}
//                             </li>
//                             <li>
//                               {"2000 " + t("pricing.AI Credits per month")}
//                             </li>
//                             <li>{"25 " + t("pricing.Tags + Pipelines")}</li>
//                             <li>{t("pricing.Live chat with real humanss")}</li>
//                           </ul>
//                           <div class="blkFri-priceMain">
//                             <span class="blkFri-price-cut">{currency}591</span>
//                             <span class="blkFri-price">
//                               {currency}197<sub>/ quarter</sub>
//                             </span>
//                           </div>
//                           <span class="blkFri-gray-text">
// 						  {t("pricing.Then only amount billed quarterly",{currency:currency,amount:471})}

//                             {/* Then only {currency}471 billed quarterly */}
//                           </span>
//                           <span class="blkFri-plan-btn" onClick={()=>handleBuyNow(currency === "$"? "Special-Offer-Black-Friday-Business-Novalya-USD-Every-3-months":"Special-Offer-Black-Friday-Business-Novalya-EUR-Every-3-months")}  >{t("pages.title.select-this-offer")}</span>
//                         </div>
//                         <div class="blkFri-plans">
//                           <div class="blkFri-plans-head">
//                             <span class="blkFri-fs-28">{t("pages.title.Unlimited")}</span>
//                           </div>
//                           <span class="blkFri-fs-20">{t("pricing.No commitment")}</span>
//                           <ul class="blkFri-list">
// 						  <li>
//                               {t(
//                                 "pricing.Facebook Messages per month Unlimited"
//                               )}
//                             </li>
//                             <li>
//                               {t(
//                                 "pricing.Instagram Messages per month Unlimited"
//                               )}
//                             </li>
//                             <li>
//                               {t("pricing.AI Credits per month Unlimited")}
//                             </li>
//                             <li>{t("pricing.Tags + Pipelines Unlimited")}</li>
//                             <li>{t("pricing.Live chat with real humanss")}</li>
//                           </ul>
//                           <div class="blkFri-priceMain">
//                             <span class="blkFri-price-cut">{currency}891 </span>
//                             <span class="blkFri-price">
//                               {currency}297<sub>/ quarter</sub>
//                             </span>
//                           </div>
//                           <span class="blkFri-gray-text">
// 						  {t("pricing.Then only amount billed quarterly",{currency:currency,amount:711})}

//                             {/* Then only {currency}711 billed quarterly */}
//                           </span>
//                           <span class="blkFri-plan-btn" onClick={()=>handleBuyNow(currency === "$"? "Special-Offer-Black-Friday-Unlimited-Novalya-USD-Every-3-months":"Special-Offer-Black-Friday-Unlimited-Novalya-EUR-Every-3-months")}>{t("pages.title.select-this-offer")}</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ) : (
//                   <div class="blkFri-panel" id="blkFri-panel2">
//                     <div class="blkFri-plans-wraper">
//                       <div class="blkFri-plans-main ">
//                         <div class="blkFri-plans">
//                           <div class="blkFri-plans-head">
//                             <span class="blkFri-fs-28">{t("pages.title.Basic")}</span>
//                           </div>
//                           <span class="blkFri-fs-20">{t("pricing.No commitment")}</span>
//                           <ul class="blkFri-list">
//                             <li>
//                               {"1200 " +
//                                 t("pricing.Facebook Messages per month")}
//                             </li>
//                             <li>
//                               {"800 " +
//                                 t("pricing.Instagram Messages per month")}
//                             </li>
//                             <li>
//                               {"800 " + t("pricing.AI Credits per month")}
//                             </li>
//                             <li>{"10 " + t("pricing.Tags + Pipelines")}</li>
//                             <li class="blkFri-gray-check">
//                               {t("pricing.Live chat with real humanss")}
//                             </li>
//                           </ul>
//                           <div class="blkFri-priceMain">
//                             <span class="blkFri-price-cut">{currency}1164</span>
//                             <span class="blkFri-price">
//                               {currency}397<sub>/ year</sub>
//                             </span>
//                           </div>
//                           <span class="blkFri-gray-text">
//                             {/* Then only {currency}756 billed yearly */}
// 							{t("pricing.Then only amount billed yearly",{currency:currency,amount:756})}

//                           </span>
//                           <span class="blkFri-plan-btn" onClick={()=>handleBuyNow(currency === "$"? "Special-Offer-Black-Friday-Basic-Novalya-USD-Yearly":"Special-Offer-Black-Friday-Basic-Novalya-EUR-Yearly")}>{t("pages.title.select-this-offer")}</span>
//                         </div>
//                         <div class="blkFri-plans">
//                           <div class="blkFri-plans-head">
//                             <span class="blkFri-fs-28">{t("pages.title.Business")}</span>
//                           </div>
//                           <span class="blkFri-fs-20">{t("pricing.No commitment")}</span>
//                           <ul class="blkFri-list">
//                             {/* <li>2400 Facebook Messages per month</li>
// 										<li>1600 Instagram Messages per month</li>
// 										<li>2000 AI Credits per month</li>
// 										<li>25 Tags + Pipelines</li>
// 										<li>Live chat with real humans</li> */}
//                             <li>
//                               {"2400 " +
//                                 t("pricing.Facebook Messages per month")}
//                             </li>
//                             <li>
//                               {"1600 " +
//                                 t("pricing.Instagram Messages per month")}
//                             </li>
//                             <li>
//                               {"2000 " + t("pricing.AI Credits per month")}
//                             </li>
//                             <li>{"25 " + t("pricing.Tags + Pipelines")}</li>
//                             <li>{t("pricing.Live chat with real humanss")}</li>
//                           </ul>
//                           <div class="blkFri-priceMain">
//                             <span class="blkFri-price-cut">{currency}2364</span>
//                             <span class="blkFri-price">
//                               {currency}797<sub>/ year</sub>
//                             </span>
//                           </div>
//                           <span class="blkFri-gray-text">
// 							{t("pricing.Then only amount billed yearly",{currency:currency,amount:1536})}

//                             {/* Then only {currency}1536 billed yearly */}
//                           </span>
//                           <span class="blkFri-plan-btn" onClick={()=>handleBuyNow(currency === "$"? "Special-Offer-Black-Friday-Business-Novalya-USD-Yearly":"Special-Offer-Black-Friday-Business-Novalya-EUR-Yearly")} >{t("pages.title.select-this-offer")}</span>
//                         </div>
//                         <div class="blkFri-plans">
//                           <div class="blkFri-plans-head">
//                             <span class="blkFri-fs-28">{t("pages.title.Unlimited")}</span>
//                           </div>
//                           <span class="blkFri-fs-20">{t("pricing.No commitment")}</span>
//                           <ul class="blkFri-list">
//                             {/* <li>Unlimited Facebook Messages per month</li>
// 										<li>Unlimited Instagram Messages per month</li>
// 										<li>Unlimited AI Credits per month</li>
// 										<li>Unlimited Tags + Pipelines</li>
// 										<li>Live chat with real humans</li> */}
//                             <li>
//                               {t(
//                                 "pricing.Facebook Messages per month Unlimited"
//                               )}
//                             </li>
//                             <li>
//                               {t(
//                                 "pricing.Instagram Messages per month Unlimited"
//                               )}
//                             </li>
//                             <li>
//                               {t("pricing.AI Credits per month Unlimited")}
//                             </li>
//                             <li>{t("pricing.Tags + Pipelines Unlimited")}</li>
//                             <li>{t("pricing.Live chat with real humanss")}</li>
//                           </ul>
//                           <div class="blkFri-priceMain">
//                             <span class="blkFri-price-cut">{currency}3564</span>
//                             <span class="blkFri-price">
//                               {currency}1197<sub>/ year</sub>
//                             </span>
//                           </div>
//                           <span class="blkFri-gray-text">
// 							{t("pricing.Then only amount billed yearly",{currency:currency,amount:2316})}

//                             {/* Then only {currency}2316 billed yearly */}
//                           </span>
//                           <span class="blkFri-plan-btn" onClick={()=>handleBuyNow(currency === "$"? "Special-Offer-Black-Friday-Unlimited-Novalya-USD-Yearly":"Special-Offer-Black-Friday-Unlimited-Novalya-EUR-Yearly")} >{t("pages.title.select-this-offer")}</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
// 		<footer class="aug-plan-footer">
// 	<div class="container">
// 		<div class="aug-plan-footer-inn">
// 			<p>Novalya © 2023-2024 &nbsp;&nbsp; | &nbsp;&nbsp; <a href="https://novalya.com/terms-and-conditions/" rel="noreferrer" target="_blank">Terms & Conditions</a></p>
// 		</div>	
// 	</div>
// </footer>
//       </div>
//     </>
//   );
};

export default Offers;
