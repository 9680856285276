import React, { useContext, useEffect, useState } from "react";
import "./UpcomingEvents.css";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { useTranslation } from "react-i18next";
import { germanEventCharge, getTicketCount } from "backendServices/ApiCalls";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";

const UpcomingEvents = () => {

    const { t } = useTranslation();
    const { loginUserData } = useContext(CustomProvider);
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });
    const [loading, setLoading] = useState(false)
    const [tickets, setTickets] = useState({
        ticketNum: 0,
        ticketLeft: 120
    })

    const navigate = useNavigate();

    const handleBuy = async () => {
        setLoading(true)
        // let regionCode = localStorage.getItem("regionCode")
        // if (!regionCode) {
        //     const res = await (await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306')).json()
        //     console.log("response", res?.regionCode);
        //     regionCode = res?.continent_code;
        //     localStorage.setItem('regionCode', regionCode)
        // }
        // const currency = regionCode?.toUpperCase() === "EU" ? "EUR" : "USD";
        // const itemPriceId = regionCode?.toUpperCase() === "EU" ? "German-Event-Frankfurt-080325-EUR" : "German-Event-Frankfurt-080325-USD"
        let params = {
            customerId: loginUserData?.customerid,
            currency:"EUR",
            itemPriceId:"German-Event-Frankfurt-080325-EUR"

        };
        germanEventCharge(
            params,
            (response) => {
                console.log("resp", response.data);
                if (response.data.status === "success") {

                    window.location = response?.data?.data?.url
                } else {
                    setalertData({
                        show: true,
                        message: "Something Went Wrong, Please Try Again",
                        variant: "error",
                    });
                    setLoading(false)

                }
            },
            (error) => {
                setLoading(false)

                setalertData({
                    show: true,
                    message: error?.response?.data?.message,
                    variant: "error",
                });
            }
        );
    };

    const getTicketLeft = () => {

        getTicketCount(
            (response) => {
                if (response.data.status === "success") {
                    const total = response?.data?.data?.[0]?.total_tickets_sold
                    setTickets((data) => ({
                        ...data,
                        ticketLeft: data.ticketLeft - total
                    }))
                } else {
                    setalertData({
                        show: true,
                        message: "Something Went Wrong, Please Try Again",
                        variant: "error",
                    });

                }
            },
            (error) => {

                setalertData({
                    show: true,
                    message: error?.response?.data?.message,
                    variant: "error",
                });
            }
        );
    }

    const copyLinkInClipBoard = () => {
        navigator.clipboard.writeText(`https://event.novalya.ai?ref=${loginUserData.email}`);
        setalertData({
            show: true,
            message: t("pages.title.Link copied successfully"),
            variant: "success",
        });
    };


    useEffect(() => {
        navigate("/")
        // getTicketLeft()
    }, [])

    // useEffect(() => {
    //     setTickets((data) => ({
    //         ...data,
    //         ticketNum: loginUserData?.isAlreadyCharge
    //     }))
    // }, [loginUserData?.isAlreadyCharge])


    return (
        // <>
        //     {alertData.show && (
        //         <SweetAlert alertData={alertData} setalertData={setalertData} />
        //     )}
        //     <div className="german-events">
        //         <div className="ge-header-container">
        //             <h2 className="ge-header">{t("pages.title.ue-title")}</h2>
        //         </div>
        //         <div className="ge-main-con">
        //             {/* <div
        //                 style={{
        //                     backgroundColor: '#FFB6C1', // Light pink
        //                     padding: '15px',
        //                     borderRadius: '10px',
        //                     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        //                     width: "70%",
        //                     marginBottom: "20px",
        //                     display: "flex",
        //                     alignItems: "center",
        //                     justifyContent: "space-between"
        //                 }}
        //             >
        //                 <div style={{
        //                     display: "flex",
        //                     alignItems: "center",
        //                     justifyContent: "center",
        //                     gap: "0 15px",
        //                 }}>
        //                     <h3>{t("pages.title.ue-passed-event-location")}</h3>
        //                     <span>
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 5 3">
        //                             <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000" />
        //                             <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00" />
        //                             <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00" />
        //                         </svg>
        //                     </span>
        //                     <p className="ge-corp-header">{t("pages.title.ue-passed-event-date")}</p>
        //                 </div>

        //                 <button style={{ padding: '10px 20px', backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '8px' }}>
        //                     {t("pages.title.ue-passed-event-btn")}
        //                 </button>
        //             </div> */}


        //             <div className="ge-box-header">
        //                 <div className="ge-box-date">
        //                     <h3 >{t("pages.title.ue-date")}</h3>
        //                     <span className="ge-attendees" >{t("pages.title.ue-limit-att")}</span>
        //                 </div>

        //                 <a
        //                     href="https://www.google.com/search?sca_esv=26a7554b929691b3&sxsrf=AHTn8zr-acnHPq_SVy4N9G5MoK0gXGmizg:1737984216625&q=hotel+nh+frankfurt+airport+west+address&ludocid=8559574682821106016&sa=X&ved=2ahUKEwi9qe3e_5WLAxWBgP0HHV24AccQ6BN6BAgfEAI"
        //                     target="_blank"
        //                     rel="noopener noreferrer"
        //                     className="ge-location-link"
        //                     onclick="event.preventDefault();"
        //                 >
        //                     {t("pages.title.Location | Hotel NH Frankfurt Airport West")}
        //                 </a>
        //             </div>
        //             <img src={`https://storage.tally.so/f1dc7ac9-dc35-4768-b03c-cc59adffafab/Capture-d-ecran-2025-01-28-a-09.50.10.png`} alt="Upcoming Event" className="ge-image" />
        //             <div className="ge-box-con">
        //                 <div className="ge-box-item">

        //                     <p className="ge-corp-header">
        //                         {t("pages.title.ue-moto")}
        //                     </p>
        //                     <p>
        //                         <span className="ge-corp-header">{t("pages.title.ue-corp-title")}</span> {t("pages.title.ue-corp-team")}
        //                     </p>
        //                     <p className="ge-corp-header">
        //                         {t("pages.title.ue-corp-moto")}
        //                     </p>
        //                     <p>
        //                         <span className="ge-corp-header">{t("pages.title.ue-note")} </span>  {t("pages.title.ue-details")}
        //                     </p>
        //                     <p className="ge-disclaimer">
        //                         <span className="ge-corp-header">
        //                             {t("pages.title.ue-imp")}
        //                         </span>
        //                         {t("pages.title.ue-imp-msg")}
        //                     </p>
        //                     <p >
        //                         {t("pages.title.ue-team-msg")}
        //                     </p>
        //                     <p>
        //                         <span className="ge-corp-header">{t("pages.title.ue-team-nva")}</span>
        //                     </p>


        //                 </div>
        //                 <div className="ge-offer-con">
        //                     {
        //                         tickets.ticketNum > 0 && <p className="ge-corp-header  success-con" style={{ fontSize: "15px", color: "#fff" }}>
        //                             {
        //                                 tickets.ticketNum === 1
        //                                     ? t("pages.title.ge-single-ticket", { count: tickets.ticketNum })
        //                                     : t("pages.title.ge-many-tickets", { count: tickets.ticketNum })
        //                             }
        //                         </p>
        //                     }
        //                     <p className="ge-corp-header" style={{ fontSize: "18px" }}>
        //                         {t("pages.title.ue-discount")}
        //                     </p>
        //                     <p className="ge-corp-header">
        //                         {t("pages.title.ue-time")}
        //                     </p>
        //                     <p className="ge-corp-header">
        //                         {t("pages.title.ue-acc-price")}
        //                     </p>
        //                     <button disabled={loading} style={{ background: loading && "grey" }} className="ge-buy-btn" onClick={() => handleBuy()}>
        //                         {t("pages.title.ue-dis-price")}
        //                     </button>
        //                     <p className="ge-corp-header" style={{ textAlign: "center", color: "#ff2424" }}>
        //                         {
        //                             tickets.ticketLeft === 0
        //                                 ? t("pages.title.ue-no-spots")
        //                                 : tickets.ticketLeft === 1
        //                                     ? t("pages.title.ue-single-spot", { count: tickets.ticketLeft })
        //                                     : t("pages.title.ue-many-spot", { count: tickets.ticketLeft })
        //                         }
        //                     </p>
        //                 </div>
        //             </div>
        //             <div style={{ fontFamily: "Arial, sans-serif", width: "100%", padding: "30px 0", boxSizing: "border-box" }}>
        //                 <div style={{
        //                     display: "flex", justifyContent: "space-between", alignItems: "center"
        //                 }}>
        //                     <h1 style={{ color: "#333", marginLeft: "8px", marginBottom: "8px" }}>
        //                         {t("pages.title.ue-program")}
        //                     </h1>
        //                     <button
        //                         onClick={() => copyLinkInClipBoard()}
        //                         style={{
        //                             padding: "8px 16px",
        //                             backgroundColor: "#41CE81",
        //                             color: "#fff",
        //                             border: "none",
        //                             borderRadius: "4px",
        //                             cursor: "pointer",
        //                             fontSize: "16px"
        //                         }}
        //                     >
        //                         {t("pages.title.Share Your Invite Link")}
        //                     </button>
        //                 </div>

        //                 <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
        //                     <div style={{ flex: 1, padding: "20px", borderRadius: "10px", backgroundColor: "#f4f4f4" }}>
        //                         <h2 style={{ color: "#007bff", marginBottom: "8px" }}>{t("pages.title.ue-morning")}</h2>
        //                         <ul style={{ listStyleType: "none", padding: 0 }}>
        //                             <li style={{ marginBottom: "10px" }}><strong>
        //                                 {t("pages.title.Professional networking over coffee")}: </strong>
        //                                 {t("pages.title.One hour to build lasting partnerships")}
        //                             </li>
        //                             <li style={{ marginBottom: "10px" }}><strong>

        //                                 {t("pages.title.Novalya")}
        //                                 :</strong> {t("pages.title.The partner revolutionizing your business approach")}
        //                                 .</li>
        //                             <li style={{ marginBottom: "10px" }}><strong>
        //                                 {t("pages.title.Your ideal client")}
        //                                 :</strong> {t("pages.title.The key to a successful sales strategy")}
        //                                 .</li>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.Best practices for a professional Facebook profile")}
        //                                 .</strong></li>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.Targeting strategies")}
        //                                 :</strong> {t("pages.title.How to find your future clients in the right Facebook groups")}
        //                                 .</li>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.The Art of Prospecting")}
        //                                 :</strong> {t("pages.title.A ready-to-use script")}
        //                                 .</li>
        //                         </ul>
        //                         <p style={{ fontStyle: "italic", color: "#555" }}>{t("pages.title.Lunch Break Included")}
        //                         </p>
        //                     </div>

        //                     <div style={{ flex: 1, padding: "20px", borderRadius: "10px", backgroundColor: "#f4f4f4" }}>
        //                         <h2 style={{ color: "#007bff", marginBottom: "8px" }}>{t("pages.title.ue-afternoon")}</h2>
        //                         <ul style={{ listStyleType: "none", padding: 0 }}>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.Boost your efficiency with artificial intelligence")}
        //                                 .</strong></li>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.CRM")}
        //                                 :</strong> {t("pages.title.An essential tool to structure your customer relationships")}
        //                                 .</li>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.Novalya")}
        //                                 :</strong> {t("pages.title.Innovates to evolve your sales strategy")}
        //                                 .</li>
        //                             <li style={{ marginBottom: "10px" }}><strong>{t("pages.title.Networking")}
        //                                 :</strong> {t("pages.title.End on a professional note with new networking opportunities")}
        //                                 .</li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
        null

    );
};

export default UpcomingEvents;