import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Button,
  Paper,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  createBirthday,
  fetchBirthdayList,
  fetchCrmGroups,
  fetchMessageList,
} from "backendServices/ApiCalls";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import "./index.css";
import TabMenu from "../components/mui/TabMenu/TabMenu";
import { prospectionButtonsConfig } from "../extension/prospection/TabData";

const Birthdaydirect = () => {
  const { t } = useTranslation();

  const [message, setMessage] = useState();
  const [where, setWhere] = useState();
  const [when, setWhen] = useState();
  const [messageData, setMessageData] = useState([]);
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );
  const [showAction, setShowAction] = useState(false);
  const [savedGroups, setSavedGroups] = useState([]);
  const [actionData, setActionData] = useState({
    moveStageId: null,
    moveGroupId: null,
  });

  const { loginUserData } = useContext(CustomProvider);
  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);
  const fetchGroupdData = async () => {
    let params = {};

    fetchCrmGroups(
      params,
      (response) => {
        if (response?.data != undefined) {
          const groupsWithColor = response.data.map((group) => ({
            ...group,
            custom_color: group.custom_color || {
              r: 255,
              g: 255,
              b: 255,
              a: 1,
            },
          }));
          setSavedGroups(groupsWithColor);
        }
      },
      (error) => {}
    );
  };
  useEffect(() => {
    fetchGroupdData();
  }, []);
  const handleWhereChange = (event, newValue) => {
    setWhere(newValue);
  };

  const handleWhenChange = (event, newValue) => {
    setWhen(newValue);
  };

  const handleMessageChange = (event, newValue) => {
    setMessage(newValue);
  };

  const handleSubmit = async () => {
    if (showAction && (!actionData.moveStageId || !actionData.moveGroupId)) {
      setAlertData({
        show: true,
        message: "Group and Stage are required for the selected action.",
        variant: "error",
      });
      return;
    }
    if (!where || !when || !message) {
      setAlertData({
        show: true,
        message: "Please select all fields",
        variant: "error",
      });
      return;
    }

    const params = {
      type: where,
      birthday_type: when,
      birthday_id: message,
      action: JSON.stringify(actionData),
    };

    createBirthday(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setAlertData({
            show: true,
            message: "Created Successfully",
            variant: "success",
          });
        }
      },
      (error) => {
        setAlertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
      }
    );
  };
  const FetchBirthdayData = () => {
    let params = {};

    fetchBirthdayList(
      params,
      (response) => {
        if (response?.data?.data != undefined) {
          setWhen(response?.data?.data?.birthday_type);
          setWhere(response?.data?.data?.type);
          setMessage(response?.data?.data?.birthday_id);
        }
      },
      (error) => {}
    );
  };
  useEffect(() => {
    let params = { type: "birthday" };
    fetchMessageList(
      params,
      (response) => {
        setMessageData(response?.data?.data);
      },
      (error) => {}
    );
    FetchBirthdayData();
  }, []);

  return (
    <>
      <TabMenu buttonsConfig={prospectionButtonsConfig} />
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setAlertData} />
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h1"
          component="h2"
          color={"primary.dark"}
          sx={{ fontSize: "28px", lineHeight: "46px" }}
        >
          {t("pages.title.Wish your friends birthday automatically")}
        </Typography>
        <a
          href={t("pages.title.BIRTHDAY_VIDEO_URL")}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className="row-inner">
            <img
              src={`${ASSET_IMAGES}/you-tube-logo.png`}
              alt=""
              className="you-icon"
            />
            <div className="col-left-new">
              <span> {t("pages.title.Watch Tutorial Video")}</span>
            </div>
          </div>
        </a>
      </div>

      <Paper
        sx={{
          height: "calc(100vh - 339px)",
          paddingX: "45px",
          borderRadius: "12px",
          border: "none",
          marginBottom: "10px",
        }}
      >
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={8}>
            <div className="d-flex">
              <Typography sx={{ color: "#170F49" }} variant="h2">
                {t("pages.title.where")}:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "space-around",
                  gap: 2,
                  flexWrap: "wrap",
                  marginLeft: "80px",
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={where}
                  exclusive
                  onChange={handleWhereChange}
                  aria-label="Platform"
                  sx={{
                    display: "contents",
                    button: {
                      borderLeft: "1px solid #DEE2E6 !important",
                      ml: "0px !important",
                    },
                  }}
                >
                  <ToggleButton
                    value="message"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "175px",
                      height: "138px",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      backgroundColor: "#efebf8",
                    }}
                  >
                    <img
                      src={`${ASSET_IMAGES}/chat-icon.png`}
                      alt=""
                      style={{ width: "40px" }}
                    />
                    {t("pages.title.inbox_direct_messages")}
                  </ToggleButton>
                  <ToggleButton
                    value="feed"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "175px",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      backgroundColor: "#efebf8",
                    }}
                  >
                    <img
                      src={`${ASSET_IMAGES}/feed-icon.png`}
                      alt=""
                      style={{ width: "32px" }}
                    />
                    {t("pages.title.feed_post")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </div>

            <div className="d-flex">
              <Typography sx={{ color: "#170F49" }} variant="h2">
                {t("pages.title.when")}:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "space-around",
                  gap: 2,
                  flexWrap: "wrap",
                  marginLeft: "85px",
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={when}
                  exclusive
                  onChange={handleWhenChange}
                  aria-label="Platform"
                  sx={{
                    display: "contents",
                    button: {
                      borderLeft: "1px solid #DEE2E6 !important",
                      ml: "0px !important",
                    },
                  }}
                >
                  <ToggleButton
                    value="today"
                    style={{ width: "175px", backgroundColor: "#efebf8" }}
                  >
                    {t("pages.title.today")}
                  </ToggleButton>
                  <ToggleButton
                    value="yesterday"
                    style={{ width: "175px", backgroundColor: "#efebf8" }}
                  >
                    {t("pages.title.yesterday")}
                  </ToggleButton>
                  <ToggleButton
                    value="2dayago"
                    style={{ width: "175px", backgroundColor: "#efebf8" }}
                  >
                    {t("pages.title.2_days_ago")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </div>

            <div className="d-flex">
              <Typography sx={{ color: "#170F49" }} variant="h2">
                Select Action:
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  padding: 2,
                  gap: 2,
                  width: "343px",
                }}
              >
                <TextField
                  select
                  label="Action"
                  value={showAction}
                  onChange={(e) => {
                    setShowAction(!showAction);
                  }}
                  fullWidth
                >
                  <MenuItem value={false}>{t("pages.title.no_action")}</MenuItem>
                  <MenuItem value={true}>{t("pages.title.Add to Group")}</MenuItem>
                </TextField>
                {showAction && (
                  <>
                    <TextField
                      select
                      label="Group"
                      value={actionData.moveGroupId || ""}
                      onChange={(e) => {
                        setActionData({
                          ...actionData,
                          moveGroupId: e.target.value,
                        });
                      }}
                      fullWidth
                    >
                      {savedGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      label="Group Stage"
                      value={actionData.moveStageId || ""}
                      onChange={(e) => {
                        setActionData({
                          ...actionData,
                          moveStageId: e.target.value,
                        });
                      }}
                      fullWidth
                    >
                      {savedGroups
                        .find((group) => group.id === actionData?.moveGroupId)
                        ?.stage.sort((a, b) => a.stage_num - b.stage_num)
                        .map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {group.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </>
                )}
              </Box>
            </div>
          </Grid>

          <Grid item xs={3} sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "#170F49" }} variant="h2">
              MESSAGES
            </Typography>
            <Box sx={{ height: "calc(100vh - 460px)", overflowY: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "space-around",
                  flexDirection: "column",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={message}
                  exclusive
                  onChange={handleMessageChange}
                  aria-label="Platform"
                  sx={{
                    display: "contents",
                    button: {
                      borderLeft: "1px solid #DEE2E6 !important",
                      ml: "0px !important",
                    },
                  }}
                >
                  {messageData.map((v, index) => (
                    <ToggleButton
                      sx={
                        v.id == message
                          ? {
                              backgroundColor: "rgba(115, 82, 199, 0.08)",
                              color: "#7352C7",
                            }
                          : {}
                      }
                      value={v.id}
                      key={index}
                      style={{
                        backgroundColor: "#efebf8",
                        width: "100%",
                        height: "76px",
                        fontSize: "14px",
                      }}
                    >
                      {v.name} 
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "center" }}>
          <Button
            sx={{
              height: 50,
              borderRadius: "12px",
              border: "3px solid",
              borderColor: "primary.light",
              zIndex: 1,
              ml: 3,
            }}
            style={{ marginBottom: "10px" }}
            className="send_birthday_message"
            onClick={handleSubmit}
            variant="contained"
            type="submit"
            size="large"
          >
            {t("pages.title.send_birthday_message")}
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default Birthdaydirect;
